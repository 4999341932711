import { FC } from 'react';
import { GlobalStyle } from './CMSAdmin.style';
import { AdminContextProvider } from '../../../context/AdminContext';
import { CMSRouter } from '../../../components/CMSAdmin/CMSRouter';

export const CMSAdmin: FC = () => {
  return (
    <AdminContextProvider>
      <GlobalStyle />
      <CMSRouter />
    </AdminContextProvider>
  );
};
