import { FC } from 'react';
import sanitizeHtml from 'sanitize-html';
import { Block, Image, TextContent, Title, Subtitle } from './BackgroundImage.style';
import { SectionContent } from '../../../types';
import { IMAGE_PATH } from '../../../constants/config';
import { IsVisible } from '../../';
import { Button } from '../../styled';
interface SectionParams {
  content: SectionContent[];
  offset?: number;
}

export const BackgroundImage: FC<SectionParams> = ({ content, offset = 0 }) => {
  return (
    <Block>
      {content.map((item, index) => {
        return (
          <IsVisible key={String(index)} index={index} offset={offset}>
            <Image
              size="100%"
              src={item.image && item.image.indexOf('://') > 0 ? item.image : IMAGE_PATH + item.image}
              css={item.css}
            />
            <TextContent color={item.color}>
              {!item.title ? null : <Title>{item.title}</Title>}
              {!item.subtitle ? null : (
                <Subtitle
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(item.subtitle),
                  }}
                />
              )}
              {item.buttonLabel && item.buttonLink ? (
                <Button layout="primary" to={`${item.buttonLink}`}>
                  {item.buttonLabel}
                </Button>
              ) : null}
            </TextContent>
          </IsVisible>
        );
      })}
    </Block>
  );
};
