import { useCallback, FC } from 'react';
import { FormFieldType } from '../../types';
import { Input, Textarea, Select, Checkbox, EditorContainer } from '../styled/Form.style';
import { UploadImageFile } from './UploadImageFile';
import { RichText } from './Wysiwyg';
export interface FormFieldData {
  fieldName: string;
  fieldValue: string;
}

interface FormFieldDisplay extends FormFieldType {
  setFormValues: (value: {}) => void;
  fieldsWithError: string[];
  setFieldsWithError: (values: string[]) => void;
  formValues: {};
  enteredValue: string;
}

export const FormField: FC<FormFieldDisplay> = ({
  formFieldName,
  formFieldPlaceholder,
  formFieldType,
  formFieldRequired,
  formFieldValues,
  formFieldSize,
  setFormValues,
  fieldsWithError,
  setFieldsWithError,
  enteredValue,
}) => {
  const setEnteredValue = useCallback(
    (val: string) => {
      setFormValues((currentState: FormFieldData[]) => {
        if (!formFieldName)
          return {
            ...currentState,
          };

        return {
          ...currentState,
          [formFieldName]: val === undefined ? '' : val,
        };
      });

      //clear the error message
      if (formFieldRequired && enteredValue && formFieldName && fieldsWithError?.includes(formFieldName)) {
        let errorFields: string[] = [];

        errorFields = fieldsWithError?.filter((item: string) => item !== formFieldName);
        setFieldsWithError(errorFields);
      }
    },
    [formFieldRequired, formFieldName, enteredValue, fieldsWithError, setFieldsWithError, setFormValues],
  );

  const changeValueHandler = useCallback(
    (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
      setEnteredValue(event.currentTarget.value);
    },
    [setEnteredValue],
  );

  switch (formFieldType) {
    case 'text': {
      return (
        <Input
          type="text"
          placeholder={formFieldPlaceholder}
          required={formFieldRequired}
          width={formFieldSize}
          name={formFieldName}
          value={enteredValue}
          onChange={(e) => changeValueHandler(e)}
        />
      );
    }

    case 'password': {
      return (
        <Input
          type="password"
          placeholder={formFieldPlaceholder}
          required={formFieldRequired}
          width={formFieldSize}
          name={formFieldName}
          value={enteredValue}
          onChange={(e) => changeValueHandler(e)}
        />
      );
    }

    case 'email': {
      return (
        <Input
          type="email"
          placeholder={formFieldPlaceholder}
          required={formFieldRequired}
          width={formFieldSize}
          name={formFieldName}
          value={enteredValue}
          onChange={(e) => changeValueHandler(e)}
        />
      );
    }
    case 'date': {
      return (
        <Input
          type="date"
          placeholder={formFieldPlaceholder}
          required={formFieldRequired}
          width={formFieldSize}
          name={formFieldName}
          value={enteredValue}
          onChange={(e) => changeValueHandler(e)}
        />
      );
    }
    case 'numeric': {
      return (
        <Input
          type="numeric"
          placeholder={formFieldPlaceholder}
          required={formFieldRequired}
          width={formFieldSize}
          name={formFieldName}
          value={enteredValue}
          onChange={(e) => changeValueHandler(e)}
        />
      );
    }
    case 'textarea': {
      return (
        <Textarea
          placeholder={formFieldPlaceholder}
          required={formFieldRequired}
          name={formFieldName}
          onChange={(e) => changeValueHandler(e)}
          value={enteredValue}
        />
      );
    }
    case 'checkbox': {
      return (
        <Checkbox
          type="checkbox"
          required={formFieldRequired}
          name={formFieldName}
          value="true"
          checked={enteredValue ? true : false}
          onChange={() => {}}
          onClick={() => setEnteredValue(enteredValue ? '' : '1')}
        />
      );
    }
    case 'richtext': {
      return (
        <EditorContainer>
          <RichText value={enteredValue as string} setValue={setEnteredValue} />
        </EditorContainer>
      );
    }
    case 'select': {
      return (
        <Select
          value={enteredValue}
          required={formFieldRequired}
          name={formFieldName}
          onChange={(e) => changeValueHandler(e)}
        >
          {!formFieldValues
            ? null
            : formFieldValues.split('\n').map((item, index) => {
                if (!index && !enteredValue && item) {
                  setEnteredValue(item);
                }
                return (
                  <option key={String(index)} value={item}>
                    {item}
                  </option>
                );
              })}
        </Select>
      );
    }
    case 'imageFile': {
      return (
        <>
          <Input
            type="text"
            placeholder="URL or path"
            required={formFieldRequired}
            width={formFieldSize}
            name={formFieldName}
            value={enteredValue}
            onChange={(e) => changeValueHandler(e)}
          />
          <UploadImageFile enteredValue={enteredValue as string} setEnteredValue={setEnteredValue} />
        </>
      );
    }
    case 'documentFile': {
      const uploadDocumentHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        let filename = '';
        var reader = new FileReader();
        reader.onload = () => {
          setEnteredValue('#@#@attachment/' + filename + '/' + reader.result);
        };

        const allowedMimeTypes = [
          'image/jpeg',
          'image/png',
          'application/pdf',
          'application/rtf',
          'application/vnd.ms-powerpoint',
          'application/vnd.openxmlformats-officedocument.presentationml.presentation',
          'application/vnd.oasis.opendocument.text',
          'application/vnd.oasis.opendocument.presentation',
          'text/plain',
          'text/html',
        ];

        if (event.target && event.target.files && event.target.files.length) {
          if (allowedMimeTypes.includes(event.target.files[0].type)) {
            filename = event.target.files[0].name;
            reader.readAsDataURL(event.target.files[0]);
          } else {
            alert('The file type is not allowed.');
          }
        }
      };

      return (
        <>
          <Input
            type="file"
            placeholder="URL or path"
            required={formFieldRequired}
            width={formFieldSize}
            name={formFieldName}
            onChange={(e) => uploadDocumentHandler(e)}
          />
        </>
      );
    }
    default: {
      return <></>;
    }
  }
};
