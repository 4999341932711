import { FC } from 'react';
import { Block } from './ShowOnScroll.style';

export const ShowOnScroll: FC<{ elementsCount?: number; elementHeight?: number }> = ({
  children,
  elementsCount = 0,
  elementHeight = 0,
}) => {
  return (
    <Block elementsCount={elementsCount} elementHeight={elementHeight}>
      {children}
    </Block>
  );
};
