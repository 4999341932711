import styled, { keyframes } from 'styled-components';
import { COLORS } from '../../constants/colors';
import { SITE_WIDTH_AS_NUMBER } from '../../constants/config';
import { Link } from 'react-router-dom';
import { ReactComponent as DOSLogo } from '../../assets/devocean-solutions-animation2.svg';

const rotate = keyframes`
    from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }   
`;

const rotateForward = keyframes`
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }  

`;


export const StyledLogo = styled(DOSLogo)<{ paused: number; reversed: boolean; scrolly: number }>`
  height: 70px;
  margin-top: 4px;

  & #logo-background {
    display: none;
  }
  & #cog-animation-mask {
    fill: ${(props) => (props.scrolly ? COLORS.backgroundColor : 'transparent')};
  }
  & #cog-original {
    display: ${(props) => (props.scrolly ? 'none' : 'block')};
  }
  & #cog1 {
    display: ${(props) => (props.scrolly ? 'bloack' : 'none')};
    animation: 2s ${(props) => (props.reversed ? rotateForward : rotate)} infinite linear;
    transform-origin: 50% 50%;
    transform-box: fill-box;
    animation-play-state: ${(props) => (props.paused ? 'paused' : 'start')};
  }
  & #DevoceanSolutions {
    fill: #fff;
  }
`;

export const Logo = styled.div`
  width: 150px;
`;

export const NavigationContainerSpaceFill = styled.div`
  background: ${COLORS.backgroundColor};
  height: 80px;
  width: 100%;
`;

export const NavigationContainerWrapper = styled(NavigationContainerSpaceFill)`
  position: fixed;
  z-index: 100;
  display: flex;
  justify-content: center;
`;

export const NavigationContainer = styled.div`
    align-items: center;
    background: ${COLORS.backgroundColor};
    box-sizing: border-box;
    display: flex;
    height: 100%;
    justify-content: space-between;
    max-width: ${SITE_WIDTH_AS_NUMBER}px;
    padding: 0px 15px;
    top: 0;
    width: 100%;
`;

export const NavItems = styled.ul`
  list-style-type: none;
  padding: 0;
  flex: 1;
  text-align: right;
  padding-top: 10px;
`;
export const NavItem = styled.li`
  display: inline;
  margin-left: 10px;
  margin-right: 10px;
`;
export const NavLink = styled(Link)<{ selected: boolean }>`
  text-decoration: none;
  color: ${COLORS.navigationLinkColor};
  padding: 5px;
  border-bottom: 2px ${(props) => (props.selected ? COLORS.secondColor : 'none')} solid;
  font-style: normal;
  font-weight: normal;
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
  &:hover {
    border-bottom: 2px ${COLORS.secondColor} solid;
  }
`;

export const NavBar = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
`;


// Mobile navigation

export const HamburgerWrapper = styled.button`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
  overflow: hidden;

  background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;

  z-index: 10;
`;

export const Hamburger = styled.div<{ active: boolean }>`
  & {
    display: inline-block;
    width: 28px;
    height: 28px;
    border-top: 2px solid ${(props) => (props.active ? 'transparent' : 'white')};
    transition: 0.3s;
    position: absolute;
    top: 6px;
  }
  & > div:first-of-type {
    transform: rotateZ(${(props) => (props.active ? '45deg' : '0')});
    transition: 0.3s;
    transform-origin: center;
  }
  & > div:last-of-type {
    transform: rotateZ(${(props) => (props.active ? '135deg' : '0')});
    margin-top: ${(props) => (props.active ? '-2px' : '6px')};
    transition: 0.3s;
    transform-origin: center;
  }
  & > div {
    & {
      border-bottom: ${(props) => (props.active ? '2px' : '2px')} solid white;
      margin-top: 6px;
      width: 100%;
      float: right;
    }
  }
`;

export const MobileNavigation = styled.div<{ isOpen: boolean }>`
  position: fixed;
  bottom: 0;
  left: ${props => props.isOpen ? '0' : '100%'};
  width: 100%;
  height: 100%;
  transition: 0.4s;
  background: ${props => props.isOpen ? 'rgba(0,0,0, 0.9)' : 'transparent'};
  opacity: ${props => props.isOpen ? '1' : '0'};
  box-sizing: border-box;
  padding-top: 80px;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const MobileNavLink = styled(Link)<{ selected: boolean }>`
  background-color: ${props => props.selected ? `rgba(18, 22, 36, 0.9);` : 'transparent'};
  border-bottom: 1px solid;
  border-color: rgba(65, 65, 95, 0.3);
  box-sizing: border-box;
  color: ${props => props.selected ? COLORS.secondColor : 'white'};
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 400;
  padding: 15px 30px;
  text-align: right;
  width: 100%;

  &:hover {
    background-color: rgba(18, 22, 36, 0.9);
  }

  &:last-child {
    border-bottom: none;
  }
`;
