import styled from 'styled-components';
import { COLORS } from '../../constants/colors';
import { SectionBlock } from '../SectionContainer/SectionContainer.style';
import { Title } from '../../components/SectionTitle/SectionTitle.style';

export const ModalContainer = styled.div<{ isopen: boolean }>`
  position: fixed;
  overflow: hidden;
  top: ${(props) => (props.isopen ? '0' : '-1000px')};
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.65);
  width: ${(props) => (props.isopen ? '100%' : '0')};
  height: ${(props) => (props.isopen ? '100%' : '0')};
  display: flex;
  opacity: ${(props) => (props.isopen ? '1' : '0')};
  align-items: center;
  justify-content: center;
  padding: 1.5em 1em;
  z-index: 999;
  box-sizing: border-box;
  transition: opacity 0.5s;
`;

export const ModalOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

export const ModalBody = styled.div`
  z-index: 2;
  position: relative;
  margin: 0 auto;
  background-color: ${COLORS.backgroundColor};
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-radius: 3px;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 100%;
  max-width: 100%;
  padding: 15px 20px;
  color: #c3c0c0;
  ${SectionBlock} {
    margin-top: 0px !important;
    margin: 30px !important;
  }
  ${Title} {
    font-size: 1.5rem;
    margin-bottom: 0px;
  }
`;
export const ModalClose = styled.span`
  position: fixed;
  color: #c3c0c0;
  cursor: pointer;
  font-size: 2rem;
  line-height: 1.5rem;
  padding: 3px;
  background: rgba(0, 0, 0, 0.25);
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  box-sizing: border-box;
  display: inline-block;
  text-align: center;
  transition: 0.1s;
  border-bottom-right-radius: 3px;
  &:hover {
    background: rgba(0, 0, 0, 0);
    font-size: 2.2rem;
  }
`;
