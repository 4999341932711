import styled from 'styled-components';

export const Block = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AppLogo = styled.img`
  height: 80px;
  margin-top: -40px;
  position: absolute;
  top: 50%;
`;
