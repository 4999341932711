import React, { FC } from 'react';
import { Block, IconBoxContainer, Title, Text, Image, ImageContainer } from './IconBox.style';
import { SectionContent } from '../../../types';
import { IsVisible } from '../../';
import { IMAGE_PATH } from '../../../constants/config';

interface SectionParams {
  content: SectionContent[];
  offset?: number;
}

export const IconBox: FC<SectionParams> = ({ content, offset = 0 }) => {
  return (
    <Block>
      {content.map((item, index) => {
        return (
          <IsVisible key={String(index)} index={index} offset={offset}>
            <IconBoxContainer key={String(index)}>
              <ImageContainer>
                {item.image && <Image src={item.image.indexOf('/') > 0 ? item.image : IMAGE_PATH + item.image} />}
              </ImageContainer>
              <Title>{item.title}</Title>
              <Text>{item.text}</Text>
            </IconBoxContainer>
          </IsVisible>
        );
      })}
    </Block>
  );
};
