import { FC, useState, useEffect, useCallback } from 'react';
import { Block, Navigation, Prev, Next } from './Carousel.style';

let timer: ReturnType<typeof setTimeout>;

export const Carousel: FC<{ elementsCount?: number }> = ({ children, elementsCount = 0 }) => {
  const [showElementIndex, setElementIndex] = useState(0);

  const autoNextElementIndex = useCallback(() => {
    setElementIndex((currentState) => {
      if (currentState >= elementsCount) return 1;
      else return currentState + 1;
    });
    clearTimeout(timer);
    timer = setTimeout(() => autoNextElementIndex(), 5000);
  }, [elementsCount]);

  useEffect(() => {
    autoNextElementIndex();
  }, [autoNextElementIndex]);

  const nextElementHandler = () => {
    clearTimeout(timer);
    setElementIndex((currentState) => {
      if (currentState >= elementsCount) return 1;
      else return currentState + 1;
    });
  };

  const previousElementHandler = () => {
    clearTimeout(timer);
    setElementIndex((currentState) => {
      if (currentState === 0) return elementsCount;
      else return currentState - 1;
    });
  };

  return (
    <Block index={showElementIndex}>
      {elementsCount > 1 ? (
        <Navigation>
          <Prev onClick={previousElementHandler}>&lang;</Prev>
          <Next onClick={nextElementHandler}>&rang;</Next>
        </Navigation>
      ) : null}
      {children}
    </Block>
  );
};
