import styled from 'styled-components';
import { COLORS } from '../../constants/colors';

export const PageWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  min-height: 100vh;
  background-color: #black;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const PageContent = styled.div<{ backgroundImage?: string }>`
  & {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: ${(props) =>
      props.backgroundImage
        ? COLORS.backgroundColor + ' url("' + props.backgroundImage + '") no-repeat'
        : COLORS.backgroundColor};
    background-size: 100% auto;
    box-sizing: border-box;
  }
  & section:first-of-type h1 {
    @media (max-width: 768px) {
      font-size: 2rem;
    }
  }
`;
