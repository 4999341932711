import styled from 'styled-components';
import { COLORS } from '../../../constants/colors';

export const Block = styled.div`
  background-color: ${COLORS.boxBackgroundColor};
  //text-align: center;
  max-width: 400px;
  position: relative;
  left: 50vw;
  top: 50vh;
  margin-left: -200px;
  margin-top: -150px;
  padding: 30px;
  box-sizing: border-box;
`;

export const Label = styled.div`
  color: ${COLORS.secondColor};
  font-size: 1.2rem;
  font-weight: bold;
  margin: 20px 0 10px 0;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 30px;
`;

export const ErrorContainer = styled.div`
  color: ${COLORS.errorRed};
`;
export const MessageContainer = styled.div`
  color: ${COLORS.successGreen};
`;
