import { useState, FC, useEffect } from 'react';
import { Block } from './Form.style';
import {
  FormFieldBox,
  FieldLabel,
  ButtonContainer,
  FieldErrorMessage,
  FormErrorMessage,
  FormResponseText,
} from '../../styled';
import { FormButton } from '../../styled';
import { FormField } from '../..';
import { submitForm } from './SubmitForm';
import { SectionForm, SectionContent, FormFieldType } from '../../../types';
import queryString from 'query-string';
interface SectionParams {
  formData: SectionForm;
  content?: SectionContent[];
  callBack?: (data: {}) => void;
}

interface FormValueParams {
  [key: string]: string;
}

export const Form: FC<SectionParams> = ({ formData, callBack, content = [] }) => {
  const [sending, setSending] = useState(false);
  const [fieldsWithError, setFieldsWithError] = useState<string[]>([]);
  const [formFields, setFormFields] = useState<FormFieldType[]>([]);
  const [formValues, setFormValues] = useState<FormValueParams>({});
  const [formResponse, setFormResponse] = useState({
    responseText: '',
    errorMessage: '',
  });

  useEffect(() => {
    setSending(false);
  }, [formResponse]);

  useEffect(() => {
    const queryValues = queryString.parse(window.location.search);
    if (formData && formData.fields && formData.fields.length) {
      setFormFields(formData.fields);
    } else if (content.length) {
      const data: FormFieldType[] = content.map((item) => {
        let defaultValue = item.formFieldDefaultValue ? item.formFieldDefaultValue : '';

        if (item.formFieldName && queryValues && queryValues[item.formFieldName])
          defaultValue = queryValues[item.formFieldName] as string;

        return { ...item, formFieldDefaultValue: defaultValue };
      });
      setFormFields(data as FormFieldType[]);
    }
  }, [formData, content]);

  useEffect(() => {
    let initialFormValues = {};
    formFields.map((item) => {
      if (item.formFieldName)
        initialFormValues = { ...initialFormValues, [item.formFieldName]: item.formFieldDefaultValue as string };
      return null;
    });
    setFormValues(initialFormValues);
  }, [formFields]);

  const formSubmitButtonHandler = () => {
    let hasError = false;
    let errorFields: string[] = [];

    formFields.map((item) => {
      if (!item) return null;

      if (item.formFieldRequired) {
        hasError = true;

        if (item.formFieldName && item.formFieldName in formValues) {
          Object.entries(formValues).forEach((entry) => {
            if (entry[0] === item.formFieldName) {
              if (entry[1]) {
                hasError = false;
              }
            }
          });

          if (hasError) errorFields.push(item.formFieldName);
          setFieldsWithError(errorFields);
        }
      }
      return true;
    });

    if (!errorFields.length) {
      if (callBack) callBack(formValues);
      else if (formData && formData.submitUrl) {
        setSending(true);
        submitForm(formData.submitUrl, formValues, setFormResponse);
      }

      let clearEnteredFormValues = {};
      formFields.map((item) => {
        if (item.formFieldName) clearEnteredFormValues = { ...clearEnteredFormValues, [item.formFieldName]: '' };
        return null;
      });
      setFormValues(clearEnteredFormValues);
    }
  };

  if (sending)
    return (
      <Block textPosition="center">
        <FormResponseText>Sending...</FormResponseText>
      </Block>
    );

  if (formResponse.responseText && !formResponse.errorMessage) {
    return (
      <Block textPosition="center">
        <FormResponseText>{formResponse.responseText}</FormResponseText>
      </Block>
    );
  }

  return (
    <Block textPosition="left">
      {formResponse.errorMessage ? <FormErrorMessage>{formResponse.errorMessage}</FormErrorMessage> : null}
      {formFields.map((item, index) => {
        if (!item) return null;
        const errorMessage: string =
          item.formFieldName && fieldsWithError?.includes(item.formFieldName) ? 'This field is required' : '';
        return (
          <FormFieldBox key={String(index)} width={item.formFieldSize === 'half' ? '50%' : '100%'} error={errorMessage}>
            <FieldLabel required={item.formFieldRequired ? item.formFieldRequired : false}>
              {item.formFieldLabel}
            </FieldLabel>
            <FormField
              formFieldName={item.formFieldName}
              formFieldPlaceholder={item.formFieldPlaceholder}
              formFieldType={item.formFieldType}
              formFieldRequired={item.formFieldRequired}
              formFieldDefaultValue={item.formFieldDefaultValue}
              enteredValue={item.formFieldName && formValues[item.formFieldName] ? formValues[item.formFieldName] : ''}
              formFieldValues={item.formFieldValues}
              formFieldSize={item.formFieldSize}
              setFormValues={setFormValues}
              formValues={formValues}
              fieldsWithError={fieldsWithError}
              setFieldsWithError={setFieldsWithError}
            />
            {errorMessage ? <FieldErrorMessage>{errorMessage}</FieldErrorMessage> : null}
          </FormFieldBox>
        );
      })}
      <ButtonContainer
        sinlgeButton={formData && formData.secondButtonLabel && formData.secondButtonLabel !== undefined ? true : false}
      >
        <FormButton onClick={formSubmitButtonHandler} layout="primary">
          {formData && formData.buttonLabel !== undefined ? formData.buttonLabel : 'Submit'}
        </FormButton>
        {formData &&
        formData.secondButtonLabel !== undefined &&
        formData.secondButtonStyle &&
        formData.secondButtonCallback ? (
          <FormButton onClick={formData.secondButtonCallback} layout="delete">
            {formData.secondButtonLabel}
          </FormButton>
        ) : null}
      </ButtonContainer>
    </Block>
  );
};
