import { Pages } from '../types';

const PAGES: Pages = {
  home: {
    title: 'Home',
    linkTo: '/'
  },
  careers: {
    title: 'Careers',
    linkTo: '/careers'
  },
  about: {
    title: 'About',
    linkTo: '/about'
  },
  contacts: {
    title: 'Contact us',
    linkTo: '/contact'
  },
  privacyPolicy: {
    title: 'Privacy Policy',
    linkTo: '/privacy-policy'
  },
  cookies: {
    title: 'Cookie Policy',
    linkTo: '/cookies'
  }
};

export { PAGES };