import { FC } from "react";
import { DraggableItem } from "./DragAndDropList.style";
import DragOverContainer from "./DragOverContainer";

interface PropsTypes {
  content: JSX.Element;
  index: number;
  dragElementOver: number;
  setDragElementOver: (index: number) => void;
  onDropOverHandler: (event: React.DragEvent<HTMLDivElement>) => void;
  setDragElement: (index: number) => void;
  dragActive: boolean;
}

const NavItemElement: FC<PropsTypes> = ({
  content,
  index,
  dragElementOver,
  setDragElementOver,
  onDropOverHandler,
  setDragElement,
  dragActive,
}) => {
  return (
    <>
      {index ? null : (
        <DragOverContainer
          index={index}
          dragElementOver={dragElementOver}
          setDragElementOver={setDragElementOver}
          onDropOverHandler={onDropOverHandler}
          setDragElement={setDragElement}
          dragActive={dragActive}
        />
      )}
      <DraggableItem
        draggable={true}
        onDragStart={(event) => {
          setTimeout(() => {
            setDragElement(index);
          }, 100);
        }}
        onDragEnd={() => {
          setDragElementOver(-1);
          setDragElement(-1);
        }}
      >
        {content}
      </DraggableItem>
      <DragOverContainer
        index={index + 1}
        dragElementOver={dragElementOver}
        setDragElementOver={setDragElementOver}
        onDropOverHandler={onDropOverHandler}
        setDragElement={setDragElement}
        dragActive={dragActive}
      />
    </>
  );
};

export default NavItemElement;
