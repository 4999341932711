import React, { FC, useState, useCallback } from 'react';
import { Block, Label, ButtonsContainer, ErrorContainer, MessageContainer } from './Login.style';
import { FormButton } from '../../styled';
import { Input } from '../../styled';
import { api } from '../Api';
import { useAdminContext } from '../../../context/AdminContext';
import { useCookies } from 'react-cookie';

export const Login: FC = () => {
  const [loginEmail, setLoginEmail] = useState('');
  const [accessCode, setAccessCode] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [store, setStore] = useAdminContext();
  const [cookies] = useCookies(['token']);

  const emailAddressHandler = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    setLoginEmail(event.currentTarget.value);
  };

  const accessCodeHandler = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    setAccessCode(event.currentTarget.value);
  };

  const loginButtonHandler = useCallback(() => {
    api({ do: 'cms', email: loginEmail, token: accessCode }, (data) => {
      if (data.status === 'logged') {
        setMessage('Welcome back!');
        setError('');

        setStore({ email: loginEmail, token: accessCode, status: 2 });
      } else if (data.error) {
        setError(data.error);
      }
    });
  }, [loginEmail, accessCode, setStore]);

  const sendSuperSecretCodeHandler = useCallback(() => {
    api({ do: 'code', email: loginEmail }, (data) => {
      if (data.status === 'sent') {
        setMessage('Please check your email.');
        setError('');
      } else if (data.error) {
        setError(data.error);
      }
    });
  }, [loginEmail]);

  if (cookies.token || store.token) return <></>;

  return (
    <Block>
      {error ? <ErrorContainer>{error}</ErrorContainer> : null}
      {message ? <MessageContainer>{message}</MessageContainer> : null}
      <Label>E-mail</Label>
      <Input type="text" onChange={(e) => emailAddressHandler(e)} defaultValue={store.email} />
      <Label>Access code</Label>
      <Input type="password" onChange={(e) => accessCodeHandler(e)} />

      <ButtonsContainer>
        <FormButton layout="primary" onClick={sendSuperSecretCodeHandler}>
          Send code
        </FormButton>
        <FormButton layout="primary" onClick={loginButtonHandler}>
          Login
        </FormButton>
      </ButtonsContainer>
    </Block>
  );
};
