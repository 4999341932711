import styled, { css } from 'styled-components';
import { COLORS } from '../../../constants/colors';

export const Block = styled.div<{ index?: number }>`
  display: flex;
  align-items: center;

  & em {
    position: absolute;
    float: left;
    opacity: 0;
    transition: 0.3s;
    width: 100%;
    z-index: 1;
    left: -100%;
    & div {
      left: -100%;
    }
  }
  & em:nth-child(${(props) => (props.index && props.index > 0 ? props.index : 1)}) {
    position: static;
    float: none;
    opacity: 1;
    transition: 0.3s;
    z-index: 2;
    left: 0;
    & div {
      opacity: 100;
      transition: 0.5s;
      left: 0;
      width: 100%;
    }
  }
`;

export const Navigation = styled.div`
  position: absolute;
  z-index: 5;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 0;
`;

const buttonStyle = css`
  background: none;
  border: 0;
  font-size: 5rem;
  font-weight: lighter;
  color: ${COLORS.textColor};
  opacity: 0.2;
  appearance: default;
  cursor: pointer;
  &:hover {
    opacity: 0.6;
  }
  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

export const Prev = styled.button`
  ${buttonStyle}
`;
export const Next = styled.button`
  ${buttonStyle}
`;
