import { useCallback } from 'react';
import { useMemo } from 'react';
import { FC, useState, useEffect, useRef } from 'react';
import { Block } from './IsVisible.style';

export const IsVisible: FC<{ index?: number; offset?: number, scrollIntoView?: boolean }> = ({ children, index = 0, offset = 0, scrollIntoView = false }) => {
  const [visible, setVisible] = useState(false);

  const myComponentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollIntoView) {
      myComponentRef.current?.scrollIntoView();
    }
  }, [scrollIntoView]);

  const offsetTop = useMemo(() => {
    return !offset ? 0 : offset * index;
  }, [offset, index]);

  const handleScroll = useCallback(() => {
    const componentOffsetTop = myComponentRef.current ? myComponentRef.current.offsetTop + offsetTop : offsetTop;

    if (!visible && myComponentRef.current && window.scrollY + window.innerHeight / 1.2 > componentOffsetTop) {
      setVisible(true);
    } else if (!visible && myComponentRef.current && window.scrollY < componentOffsetTop - window.innerHeight / 1.1)
      setVisible(false);
  }, [offsetTop, visible]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    handleScroll();

    window.addEventListener('click', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.addEventListener('click', handleScroll);
    };
  }, [handleScroll]);

  return (
    <Block ref={myComponentRef} className={visible ? 'visible' : 'invisible'}>
      {children}
    </Block>
  );
};
