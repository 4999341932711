import React from 'react';
import sanitizeHtml from 'sanitize-html';
import { Block, TextContent, Title, Subtitle, Text, Date, TimelineBorder, SectionContainer } from './Timeline.style';
import { SectionContent } from '../../../types';
import { IsVisible } from '../../';

interface SectionParams {
  content: SectionContent[];
  offset?: number;
}

export const Timeline: React.FC<SectionParams> = ({ content, offset = 0 }) => {
  return (
    <SectionContainer>
      {content.map((item, index) => {
        let textPosition = item.textPosition === 'left' ? 'row-reverse' : 'row';

        return (
          <IsVisible key={String(index)} index={index} offset={offset}>
            <Block textPosition={textPosition}>
              <TimelineBorder>
                <Date>{item.date}</Date>
              </TimelineBorder>
              {item.text ? (
                <TextContent>
                  {!item.subtitle ? null : <Subtitle>{item.subtitle}</Subtitle>}
                  {!item.title ? null : <Title>{item.title}</Title>}
                  <Text dangerouslySetInnerHTML={{ __html: sanitizeHtml(item.text) }} />
                </TextContent>
              ) : null}
            </Block>
          </IsVisible>
        );
      })}
    </SectionContainer>
  );
};
