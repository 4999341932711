import styled from 'styled-components';
import { COLORS } from '../../../constants/colors';

export const Block = styled.div`
  background-color: ${COLORS.boxBackgroundColor};
  text-align: center;
  & > div:last-child {
    border: 0;
  }
  @media (min-width: 768px) {
    padding: 30px 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2rem;
  }
  @media (max-width: 768px) {
    display: block;
  }
  & > em {
    @media (min-width: 768px) {
      padding: 0 20px;
      border-right: 1px ${COLORS.boxBorderColor} solid;
    }

    @media (max-width: 768px) {
      padding: 20px 0px;
      margin: 0 20px;
      border-bottom: 1px ${COLORS.boxBorderColor} solid;
    }
  }
`;

export const Title = styled.div`
  color: ${COLORS.secondColor};
  font-size: 2.6rem;
  font-weight: bold;
`;

export const Text = styled.p`
  color: ${COLORS.textColor};
  font-size: 1.1rem;
  line-height: 1.5rem;
  margin: 15px 0 0 0;
`;
