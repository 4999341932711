import styled from 'styled-components';
import { COLORS } from '../../../constants/colors';

export const SectionContainer = styled.div`
  @media (min-width: 769px) {
    margin-top: 100px;
    & em:last-child div div {
      border: 0;
    }
  }
  & > em {
    display: block;
  }
`;

export const Block = styled.div<{ textPosition: string }>`
  display: flex;
  flex-direction: ${(props) => props.textPosition};
  justify-content: space-around;
  background: ${COLORS.backgroundColor};
  max-width: 940px;
  margin: 0 auto;
  @media (max-width: 769px) {
    flex-direction: column;
  }
`;

export const TextContent = styled.div`
  color: ${COLORS.textColor};
  font-size: 1.1rem;
  line-height: 1.5rem;
  flex: 1;
  padding-bottom: 40px;
  @media (min-width: 769px) {
    margin-top: -50px;
    margin-bottom: 50px;
    padding-left: 20px;
  }
  @media (max-width: 769px) {
    width: 100%;
  }
`;

export const Title = styled.div`
  color: ${COLORS.textColor};
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 2.4rem;
`;

export const Subtitle = styled.div`
  color: ${COLORS.secondColor};
  font-size: 1.1rem;
  margin-bottom: 10px;
`;

export const Text = styled.p`
  color: ${COLORS.sectionSubtitleColor};
  font-size: 1.2rem;
  line-height: 1.7rem;
  margin: 10px 0 0 0;
`;

export const Date = styled.span`
  color: ${COLORS.successGreen};
  background-color: ${COLORS.backgroundColorLight};
  font-size: 0.9rem;
  font-weight: bold;
  padding: 7px 14px;
  border-radius: 13px;
  margin-top: -5px;
`;

export const TimelineBorder = styled.div`
  @media (max-width: 769px) {
    margin-bottom: 20px;
  }
  @media (min-width: 769px) {
    padding-right: 20px;
    height: auto;
    width: 70px;
    border-right: 1px solid ${COLORS.boxBackgroundColor};
    &::before {
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      border: 4px solid ${COLORS.backgroundColor};
      border-radius: 100%;
      background-color: ${COLORS.secondColor};
      margin-left: 82px;
    }
  }
`;
