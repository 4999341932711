import styled from 'styled-components';
import { COLORS } from '../../../constants/colors';
import IconMail from '../../../assets/tep_icon_mail.svg';
import IconLinkedin from '../../../assets/tep_icon_linkedin.svg';
import IconFacebook from '../../../assets/tep_icon_facebook.svg';

export const SectionContainer = styled.div`
  & > em {
    display: block;
  }
`;
export const Block = styled.div<{ textPosition: string }>`
  padding-top: 7px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: ${(props) => props.textPosition};
  align-items: center;
  justify-content: space-around;
  @media (max-width: 767px) {
    flex-direction: column-reverse;
  }

  & a.iconLinkedin {
    background-image: url(${IconLinkedin});
  }

  & a.iconMail {
    background-image: url(${IconMail});
  }

  & a.iconFacebook {
    background-image: url(${IconFacebook});
  }
  & a.iconLinkedin,
  a.iconMail,
  a.iconFacebook {
    background-color: ${COLORS.primaryColor};
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 100%;
    height: 36px;
    width: 36px;
    display: inline-block;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
  }

  & a.iconLinkedin:hover,
  a.iconMail:hover,
  a.iconFacebook:hover {
    background-color: ${COLORS.secondColor};
  }
`;

export const Image = styled.img<{ size: string; css?: string }>`
  max-width: 100%;
  flex: 1;
  height: 100%;
  ${(props) => (props.css ? props.css : '')}
  @media (max-width: 767px) {
    width: 100%;
  }
`;

export const ImageContainer = styled.div<{ position: string }>`
  flex: 1;
  position: relative;
  @media (width: 768px) {
    flex: 0.5;
    & > img {
      min-height: 320px;
      object-fit: cover;
    }
  }

  @media (min-width: 1280px) {
    ${(props) => (props.position === 'left' ? 'padding-left: 20px;' : 'padding-right: 20px;')}
  }
  @media (max-width: 1279px) {
    ${(props) => (props.position === 'right' ? 'margin-left: -40px;' : 'margin-right: -40px;')}
  }
`;

export const TextContent = styled.div<{ position: string }>`
  color: ${COLORS.textColor};
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: left;
  flex: 1;
  font-weight: 400;
  overflow: hidden;
  box-sizing: border-box;
  @media (max-width: 767px) {
    width: 100%;
  }
  & > div,
  > h3,
  > p {
    ${(props) => (props.position === 'left' ? 'padding-right: 4rem;' : 'padding-left: 4rem;')}
  }
`;

export const Title = styled.h3`
  color: ${COLORS.sectionTitleColor};
  font-size: 1.5rem;
  font-weight: bold;

  margin-bottom: 0.75rem;
  font-weight: 700;
    line-height: auto;
    font-size: 2rem;
  }
`;

export const Subtitle = styled.div`
  color: ${COLORS.secondColor};
  font-size: 1.1rem;
  margin-bottom: 20px;
`;

export const Text = styled.p`
  color: ${COLORS.textColor};
  line-height: 2rem;
  margin: 0 0 0 0;

  margin-bottom: 1rem;
  font-size: 1.25rem;

  & > h2 {
    font-size: 2rem;
    line-height: 1;
    font-weight: 700;
  }
  & > h3 {
    font-size: 1.5rem;
    color: ${COLORS.secondColor};
    font-weight: 700;
    margin-top: 20px;

    line-height: 1.375;
    letter-spacing: -0.01em;
  }
  & > h6 {
    font-size: 1rem;
    color: ${COLORS.secondColor};
    font-weight: 700;
    margin-bottom: 20px;
  }
  & > ul {
    margin-top: 15px;
  }

  & > ul > li {
    padding-left: 20px;

    background-image: url('data:image/svg+xml;utf8,<svg class="cr rc fj ld sc rn" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><g fill="%230091D2"><path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z"></path></g></svg>');
    background-size: 12px;
    background-repeat: no-repeat;
    background-position: left 10px;
  }
`;
