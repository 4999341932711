export const COLORS = {
  //backgroundColor: '#151718', //original background color
  backgroundColor: '#000000',
  buttonText: '#ffffff',
  primaryColor: '#1E4769',
  secondColor: '#0091D2',
  textColor: '#8A98A4',
  sectionTitleColor: '#D1DCE5',
  sectionSubtitleColor: '#8F9FAA',
  boxBackgroundColor: '#25282C',
  boxBorderColor: '#6E7D8580',
  iconsColor: '#ffffff',
  errorRed: '#F46165',
  formBorderActiveColor: '#6E7D85',
  successGreen: '#38A169',
  backgroundColorLight: '#C6F6D5',
  iconColorCssFilter: 'invert(100%) sepia(0%) saturate(0%) hue-rotate(127deg) brightness(100%) contrast(101%);',
  navigationLinkColor: '#ffffff',
  linkColor: '#8A99A6',
};
