import { FC, useCallback, useEffect } from 'react';
import { ModalContainer, ModalBody, ModalClose, ModalOverlay } from './Modal.style';

interface SectionParams {
  children: React.ReactNode;
  isOpen: boolean;
  setIsOpen: (status: boolean) => void;
  onClose?: () => void;
  escKey?: boolean;
}

export const Modal: FC<SectionParams> = ({ children, isOpen = false, setIsOpen, onClose, escKey = false }) => {
  const close = useCallback(() => {
    setIsOpen(false);
    if (onClose) onClose();
  }, [onClose, setIsOpen]);

  const handleEscape = useCallback(
    (event) => {
      if (event.keyCode === 27) close();
    },
    [close],
  );

  useEffect(() => {
    if (escKey) {
      if (isOpen) document.addEventListener('keydown', handleEscape, false);

      return () => {
        if (isOpen) document.addEventListener('keydown', handleEscape, false);
      };
    }
  }, [handleEscape, isOpen, escKey]);

  return (
    <ModalContainer isopen={isOpen}>
      <ModalOverlay onClick={close} />
      <ModalBody>
        <ModalClose onClick={close}>✕</ModalClose>
        {children}
      </ModalBody>
    </ModalContainer>
  );
};
