import styled from 'styled-components';
import { COLORS } from '../../../constants/colors';

export const Block = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${COLORS.textColor};
`;
