import { FC } from 'react';
import { SectionContainerBlock } from './SectionContainer.style';
import { SectionType } from '../../types';
import { Section } from './Section';

const haveAfterApplying = (sections: SectionType[]) => sections.find(section => section.title === 'After Applying');

const getSections = (sections: SectionType[]) => {
  let newSections: SectionType[] = sections;
  const afterApplying = haveAfterApplying(sections);
  if (afterApplying) {
    const lastIndex = sections.length - 1;
    const preLastIndex = sections.length - 2;

    const slicedSections = sections.slice(0, preLastIndex);
    newSections = [...slicedSections, sections[lastIndex], sections[preLastIndex]];
  }

  return newSections;
};

export const SectionContainer: FC<{ sections: SectionType[] }> = ({ sections }) => {

  const newSection = getSections(sections);
  
  return (
    <SectionContainerBlock>
      {!newSection || !newSection.length
        ? null
        : newSection.map((section, index) => {
            return <Section key={String(index)} item={section} isVisible={true} index={index} />;
          })}
    </SectionContainerBlock>
  );
};
