import styled from 'styled-components';
import { SITE_WIDTH } from '../../constants/config';

export const SectionContainerBlock = styled.div`
  width: 100%;
`;

export const SectionBlock = styled.section<{
  isVisible: boolean;
  fullWidth?: boolean;
  index: number;
  marginTop?: string;
}>`
  margin: 0 auto;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '30px')};
  width: ${(props) => (props.fullWidth ? '100%' : SITE_WIDTH)};
  max-width: 100%;
  padding: ${(props) => (props.fullWidth ? '0' : '0 20px')};

  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  transition: opacity 0.5s 0.25s ease-out;
  box-sizing: border-box;
`;
