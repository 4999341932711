import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import {
  NavigationContainerWrapper,
  NavigationContainer,
  NavigationContainerSpaceFill,
  NavItems,
  NavItem,
  NavLink,
  NavBar,
  HamburgerWrapper,
  Hamburger,
  StyledLogo,
  Logo,
  MobileNavigation as StyledMobileNavigation,
  MobileNavLink,
} from './Navigation.style';
import { useWindowSize } from '../../hooks/useWindowSize';
import { SITE_MOBILE_WIDTH_AS_NUMBER } from '../../constants/config';

import { NavigationLink } from '../../types';
import { PAGES } from '../../constants/pages';

let lastScrollPos = -1;


const NAVIGATION_LINKS: NavigationLink[] = [PAGES.home, PAGES.careers, PAGES.about, PAGES.contacts];

const LogoLink: React.FC = () => {
  const [animatedLogoPaused, setAnimatedLogoPaused] = useState<boolean>(true);
  const [animatedLogoForward, setAnimatedLogoForward] = useState<boolean>(false);

  const onScrollHandler = useCallback(() => {
    setAnimatedLogoForward(window.scrollY < lastScrollPos);
    setAnimatedLogoPaused(false);

    setTimeout(() => {
      setAnimatedLogoPaused(true);
    }, 100);

    lastScrollPos = window.scrollY;
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', onScrollHandler);
    return () => {
      window.removeEventListener('scroll', onScrollHandler);
    };
  }, [onScrollHandler]);

  return (
    <Logo>
      <Link to="/">
        <StyledLogo reversed={animatedLogoForward} paused={animatedLogoPaused ? 1 : 0} scrolly={window.scrollY} />
      </Link>
    </Logo>
  );
};

type DesktopNavigationProps = { activeLink: string };
const DesktopNavigation: React.FC<DesktopNavigationProps> = ({ activeLink }) => {
  return (
    <NavBar>
      <NavItems>
        {
          NAVIGATION_LINKS.map((item, index) => (
            <NavItem key={`${item.title} ${index}`}>
              <NavLink
                to={item.linkTo}
                selected={item.linkTo === activeLink}
              >
                {item.title}
              </NavLink>
            </NavItem>
          ))
        }
      </NavItems>
    </NavBar>
  )
};

type MobileNavigationProps = { activeLink: string };
const MobileNavigation: React.FC<MobileNavigationProps> = ({ activeLink }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuClick = useCallback(() => {
    setIsMenuOpen(!isMenuOpen);
  }, [isMenuOpen]);

  return (
    <>
      <HamburgerWrapper onClick={handleMenuClick}>
        <Hamburger active={isMenuOpen}>
          <div></div>
          <div></div>
        </Hamburger>
      </HamburgerWrapper>

      <StyledMobileNavigation isOpen={isMenuOpen}>
      { 
        NAVIGATION_LINKS.map((item, index) => (
          <MobileNavLink
            key={`${item}-${index}`}
            to={item.linkTo}
            selected={item.linkTo === activeLink}
            onClick={handleMenuClick}
          >
            {item.title}
          </MobileNavLink>
        ))
      }

      </StyledMobileNavigation>
    </>
  )
};

type NavigationProps = {
  activeLink: string;
};

export const Navigation: React.FC<NavigationProps> = ({ activeLink }) => {
  const { width } = useWindowSize();

  return (
    <>
      <NavigationContainerSpaceFill />
      <NavigationContainerWrapper>
        <NavigationContainer>
          <LogoLink />
          {/* <DesktopNavigation activeLink={activeLink} /> */}
          {
            width >= SITE_MOBILE_WIDTH_AS_NUMBER ? 
              <DesktopNavigation activeLink={activeLink} />
              :
              <MobileNavigation activeLink={activeLink} />
          }
        </NavigationContainer>
      </NavigationContainerWrapper>
    </>
  );
};
