import styled, { css } from 'styled-components';
import { COLORS } from '../../constants/colors';
import { Link } from 'react-router-dom';

const buttonStyle = css<{ layout: string }>`
  padding: 15px 25px;
  text-align: center;
  text-decoration: none;
  color: ${(props) =>
    props.layout === 'primary'
      ? COLORS.buttonText
      : props.layout === 'delete'
      ? COLORS.buttonText
      : COLORS.primaryColor};

  background-color: ${(props) =>
    props.layout === 'primary' ? COLORS.primaryColor : props.layout === 'delete' ? COLORS.errorRed : 'transparent'};

  border: 0;
  border-radius: 3px;
  font-size: 1rem;
  z-index: 1;
  outline: none;
  &:hover {
    background-color: ${(props) => (props.layout === 'primary' ? COLORS.secondColor : 'transparent')};
    color: ${COLORS.buttonText};
    transition: 0.2s;
  }
`;

export const Button = styled(Link)`
  height: 1.2rem;
  ${buttonStyle}
`;

export const FormButton = styled.button<{ layout: string }>`
  ${buttonStyle}
`;

export const SmallButton = styled.button`
  ${buttonStyle};
  padding: 5px 10px;
  font-size: 0.8rem;
`;

export const SmallButtonLink = styled(Link)`
  ${buttonStyle};
  padding: 5px 5px;
  font-size: 0.9rem;
`;
