import styled, { keyframes } from 'styled-components';

const fadein = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const TopBlock = styled.div`
  max-width: 48rem;
  margin: 0 auto;
`;
export const Block = styled.div`
  //height: 400px;
  margin-top: -110px;
  position: relative;
  margin-bottom: -50px;
  padding-bottom: 75%;
  margin-left: auto;
  margin-right: auto;
`;

export const Image = styled.img`
  width: 100%;
  //object-fit: cover;
  //position: absolute;
`;

export const Figure = styled.figure<{
  width?: string;
  left?: string;
  top?: string;
  right?: string;
  bottom?: string;
  maxWidth?: string;
  transition?: number;
}>`
  width: ${(props) => props.width || '100%'};
  max-width: ${(props) => props.maxWidth || '100%'};
  left: ${(props) => props.left};
  top: ${(props) => props.top};
  bottom: ${(props) => props.bottom};
  right: ${(props) => props.right};

  height: auto;
  position: absolute;
  animation: 0.5s ${fadein};
  transition-delay: ${(props) => props.transition}s;
`;
