import styled from 'styled-components';
import { COLORS } from '../../../constants/colors';

export const Block = styled.div`
  width: 100%;
  height: 100%;
  max-width: 400px;
  margin: auto;
  text-align: center;
  color: ${COLORS.textColor};
`;

export const PageItemOptions = styled.div`
  & > button {
    margin-left: 10px;
  }
`;

export const ModalContaner = styled.div`
  width: 500px;
  max-width: 100%;
  & > p {
    margin: 10px 0;
    & > input {
      margin-bottom: 15px;
    }
  }
`;

export const PageItem = styled.div`
  color: ${COLORS.textColor};
  background: ${COLORS.backgroundColor};
  text-align: center;
  border: 1px solid ${COLORS.textColor};
  margin: 10px 10px;
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
export const ModalButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
