import styled, { css } from 'styled-components';

function createCSS(elementHeight = 176) {
  let styles = '';

  for (let i = 0; i < 4; i += 1) {
    styles += `
     & em.visible:nth-child(${i}) {
      top: ${i * elementHeight}px;
      padding-bottom: 50vh;
    }
     `;
  }

  return css`
    ${styles}
  `;
}

export const Block = styled.div<{ elementsCount: number; elementHeight?: number }>`
  & em.visible {
    position: sticky;
  }

  ${(props) => createCSS()}
`;
