import styled from 'styled-components';
import { COLORS } from '../../constants/colors';

export const Block = styled.div``;

export const Title = styled.h1`
  font-size: 2.5rem;
  color: ${COLORS.sectionTitleColor};
  margin: 0;
  padding: 0;
  text-align: center;
  margin-bottom: 20px;
  font-weight: 800;
`;

export const Subtitle = styled.h2`
  font-size: 1.2rem;
  color: ${COLORS.sectionSubtitleColor};
  margin: 0 auto;
  padding: 0;
  text-align: center;
  line-height: 1.8rem;
  font-weight: normal;
  margin-bottom: 50px;
  max-width: 900px;
`;
