import { FC, useState, useEffect } from 'react';
import sanitizeHtml from 'sanitize-html';
import { useLocation } from "react-router-dom";
import { Block, Title, Text, ToggleIcon, ToggleText, Loading } from './Career.style';
import { SectionContent, PageType, SectionType } from '../../../types';
import { FormButton } from '../../styled';
import { IsVisible, Modal, SectionContainer } from '../../';
import { api } from '../../CMSAdmin/Api';

interface SectionParams {
  content: SectionContent[];
  offset?: number;
}

const CareerContent: FC<{
  text: string;
  title: string;
  index: number;
  isOpenOnInit?: boolean;
  offset?: number;
  setWantedJobRef: (data: string) => void;
}> = ({ text, title, index, isOpenOnInit = false, offset = 0, setWantedJobRef }) => {
  const [opened, setOpened] = useState(isOpenOnInit);

  useEffect(() => {
    setOpened(isOpenOnInit);
  }, [isOpenOnInit]);

  return (
    <IsVisible index={index} offset={offset} scrollIntoView={isOpenOnInit}>
      <Title onClick={() => setOpened((currentState) => !currentState)}>
        {title}
        <ToggleIcon opened={opened}>✕</ToggleIcon>
      </Title>
      <ToggleText opened={opened}>
        <Text
          opened={opened}
          dangerouslySetInnerHTML={{ __html: sanitizeHtml(text, { allowedTags: false, allowedAttributes: false }) }}
        />
        <FormButton layout="primary" onClick={() => setWantedJobRef(title)}>
          Apply now &raquo;
        </FormButton>
      </ToggleText>
    </IsVisible>
  );
};

export const Career: FC<SectionParams> = ({ content, offset = 0 }) => {
  const [modelOpened, setModalOpened] = useState(false);
  const [wantedJobRef, setWantedJobRef] = useState('');
  const [applicationContent, setApplicationContent] = useState<PageType>();

  const { search } = useLocation();

  const [openPositionOnInit, setOpenPositionOnInit] = useState<string | null>('');

  useEffect(() => {
    const params = new URLSearchParams(search);
    const position = params.get("position");
    let title: string | null = null;
    if (position === ".NET") {
      title = ".Net Developer";
    } else if (position === "React") {
      title = "Front-End Developer /React/ "
    } else if (position === "QA") {
      title = "Automation QA ";
    } else if (position === "Internship") {
      title = "Internship  "
    };
    setOpenPositionOnInit(title);
  }, [search]);


  useEffect(() => {
    if (!modelOpened) setWantedJobRef('');
  }, [modelOpened]);

  useEffect(() => {
    if (wantedJobRef) {
      api({ do: 'cms', action: 'getData', page: 'pages/application' }, (data) => {
        if (data.status === 'done') {
          setApplicationContent(data.data as PageType);
          setModalOpened(true);
        }
      });
    }
  }, [wantedJobRef]);

  return (
    <Block>
      {content.map((item, index) => {
        if (!item.text || !item.title) return null;

        return (
          <CareerContent
            index={index}
            offset={offset}
            key={String(index)}
            text={item.text}
            title={item.title}
            isOpenOnInit={item.title === openPositionOnInit}
            setWantedJobRef={setWantedJobRef}
          />
        );
      })}
      <Modal isOpen={modelOpened} setIsOpen={setModalOpened}>
        {!applicationContent ? (
          <Loading>Loading...</Loading>
        ) : (
          <SectionContainer
            sections={
              applicationContent.sections?.map((item) => {
                return {
                  ...item,
                  content: item.content?.map((row) => {
                    if (row.formFieldName === 'subject') row.formFieldDefaultValue = wantedJobRef;
                    return row;
                  }),
                };
              }) as SectionType[]
            }
          />
        )}
      </Modal>
    </Block>
  );
};
