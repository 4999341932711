import { FC, useEffect, useState, useCallback } from 'react';
import { PageType, SectionType, SectionForm } from '../../../types';
import { Block, Buttons, PageSections, SectionLabel, SectionButtons } from './CMSPageSections.style';
import { FormButton, SmallButton } from '../../../components/styled';
import { Section, Modal, DragAndDropList } from '../../../components';

import { saveButtonHandler } from '../CMSPages/ButtonHandlers';
import { useAdminContext } from '../../../context/AdminContext';

import { Form } from '../../../components/PageSection';
import { contentDisplayEffects } from '../../../components/layoutEffects/EffectComponent';

import { CMSPageSectionEdit } from './CMSPageSectionEdit';

import { cmsPageSectionFields } from '../../../components/PageSection/PageSectionRouter/cmsPageSectionFields';
import { api } from '../../../components/CMSAdmin/Api';

const formSelectValues = cmsPageSectionFields.map((item) => item.sectionType).join('\n');

const contentEffectValues = contentDisplayEffects.reduce(
  (prevVal: string, currVal: { label: string; value: string }, idx: number) =>
    !idx ? currVal.value : prevVal + '\n' + currVal.value,
  '',
);

const defaultFormData: SectionForm = {
  buttonLabel: 'Save',
  fields: [
    {
      formFieldLabel: 'Title',
      formFieldName: 'title',
      formFieldType: 'text',
      formFieldDefaultValue: '',
    },
    {
      formFieldLabel: 'Subtitle',
      formFieldName: 'subtitle',
      formFieldType: 'text',
      formFieldDefaultValue: '',
    },
    {
      formFieldLabel: 'Type',
      formFieldName: 'type',
      formFieldType: 'select',
      formFieldDefaultValue: formSelectValues.split('\n')[0],
      formFieldValues: formSelectValues,
    },
    {
      formFieldLabel: 'Full width',
      formFieldName: 'fullWidth',
      formFieldType: 'checkbox',
      formFieldDefaultValue: '',
    },
    {
      formFieldLabel: 'Layout Effect',
      formFieldName: 'effect',
      formFieldType: 'select',
      formFieldValues: contentEffectValues,
      formFieldDefaultValue: formSelectValues.split('\n')[0],
    },
    {
      formFieldLabel: 'Layout Effect Offset',
      formFieldName: 'effectOffset',
      formFieldType: 'text',
      formFieldDefaultValue: '',
    },
    {
      formFieldLabel: 'Form submit URL',
      formFieldName: 'formSubmitUrl',
      formFieldType: 'text',
      formFieldDefaultValue: '',
    },

    {
      formFieldLabel: 'Form button text',
      formFieldName: 'formButtonLabel',
      formFieldType: 'text',
      formFieldDefaultValue: '',
    },
  ],
};

export const CMSPageSections: FC<{
  editPageFile: string;
  setEditPageSections: (data: string) => void;
}> = ({ editPageFile, setEditPageSections }) => {
  const [store] = useAdminContext();

  const [modalOpened, setModalOpened] = useState(false);
  const [formData, setFormData] = useState<SectionForm>(defaultFormData);
  const [pageData, setPageData] = useState<PageType>();
  const [editPageSectionIndex, setEditPageSectionIndex] = useState(-1);
  const [editPageSectionContent, setEditPageSectionContent] = useState(false);
  const [lastLoadedPage, setLastLoadedPage] = useState('');

  const loadPageContent = useCallback(
    async (editPageFileName: string) => {
      api(
        { do: 'cms', email: store.email, token: store.token, action: 'getDataCMS', page: 'pages/' + editPageFileName },
        (data) => {
          if (data.status === 'done') {
            //check for data changes
            if (JSON.stringify(data.data) !== JSON.stringify(pageData)) {
              setModalOpened(false);
            }

            setPageData(data.data as PageType);
            window.scrollTo(0, 0);
          }
        },
      );
    },
    [store.email, store.token, pageData],
  );

  useEffect(() => {
    if (editPageFile !== lastLoadedPage) {
      // setModalOpened(false);
      setLastLoadedPage(editPageFile);
      loadPageContent(editPageFile);
    }
  }, [editPageFile, lastLoadedPage, loadPageContent]);

  const onDropHandler = useCallback(
    (sortedData: SectionType[]) => {
      if (pageData) {
        const newPageData: PageType = {
          ...pageData,
          sections: sortedData,
        };

        saveButtonHandler(newPageData, [], editPageFile, store, () => {
          setPageData(newPageData);
          setModalOpened(false);
        });
      }
    },
    [editPageFile, pageData, store],
  );

  const saveSectionHandler = useCallback(
    (data: {}) => {
      if (pageData) {
        const updatedSections = pageData.sections;

        if (
          editPageSectionIndex >= 0 &&
          updatedSections !== undefined &&
          updatedSections[editPageSectionIndex] !== undefined
        )
          updatedSections[editPageSectionIndex] = {
            ...(data as SectionType),
            content: updatedSections[editPageSectionIndex].content,
          };
        else updatedSections?.push(data as SectionType);

        const newPageData: PageType = {
          ...pageData,
          sections: updatedSections,
        };

        saveButtonHandler(newPageData, [], editPageFile, store, () => {
          setPageData(newPageData);
          setModalOpened(false);
        });
      }
    },
    [editPageFile, editPageSectionIndex, pageData, store],
  );

  const editButtonHandler = useCallback(
    async (item: SectionType, index: number) => {
      return loadPageContent(editPageFile).then(() => {
        const formFields = defaultFormData.fields.map((row) => {
          const val = Object.entries(item).find((e) => {
            if (e[0] === row.formFieldName) return e[1];
            return null;
          });

          let defaultVal = row.formFieldDefaultValue;

          if (typeof val === 'object' && val[1] !== '') defaultVal = val[1];

          row = { ...row, formFieldDefaultValue: defaultVal };

          return row;
        });

        setFormData({
          buttonLabel: 'Update',
          fields: formFields,
        });

        setEditPageSectionIndex(index);
        setModalOpened(true);
      });
    },
    [editPageFile, loadPageContent],
  );

  const reloadPageData = useCallback(async () => {
    loadPageContent(editPageFile);
  }, [loadPageContent, editPageFile]);

  if (editPageSectionContent) {
    return (
      <CMSPageSectionEdit
        pageData={pageData ?? pageData}
        setEditPageSectionContent={setEditPageSectionContent}
        onDropHandler={onDropHandler}
        saveSectionHandler={saveSectionHandler}
        editPageSectionIndex={editPageSectionIndex}
        reloadPageData={reloadPageData}
        modalOpened={modalOpened}
        setModalOpened={setModalOpened}
      />
    );
  }

  return (
    <>
      <Block>
        <Buttons>
          <FormButton layout="primary" onClick={() => setEditPageSections('')}>
            &laquo; Back
          </FormButton>
          <FormButton
            layout="primary"
            onClick={() => {
              setEditPageSectionIndex(-1);
              setFormData(defaultFormData);
              setModalOpened(true);
            }}
          >
            Add section
          </FormButton>
        </Buttons>
        <PageSections>
          <DragAndDropList
            items={
              !pageData?.sections?.length
                ? null
                : pageData.sections.map((item, index) => {
                    return (
                      <>
                        <SectionButtons>
                          <SmallButton layout="primary" onClick={() => editButtonHandler(item, index)}>
                            Edit
                          </SmallButton>
                          <SectionLabel>{item.type}</SectionLabel>
                          <SmallButton
                            layout="primary"
                            onClick={() => {
                              //check for data changes before continue
                              reloadPageData().then(() => {
                                setEditPageSectionIndex(index);
                                setEditPageSectionContent(true);
                              });
                            }}
                          >
                            Content
                          </SmallButton>
                        </SectionButtons>
                        <Section key={String(index)} isVisible={true} item={item} index={index} />
                      </>
                    );
                  })
            }
            data={!pageData?.sections?.length ? null : (pageData.sections as [])}
            onDragAndDrop={(itemid, index, data) => {
              onDropHandler(data);
            }}
            enableDelete={true}
          />
        </PageSections>
      </Block>
      <Modal isOpen={modalOpened} setIsOpen={setModalOpened} onClose={() => {}}>
        <Form formData={formData} callBack={saveSectionHandler} />
      </Modal>
    </>
  );
};
