import { FC } from 'react';
import { SectionBlock } from './SectionContainer.style';
import { ButtonLinks, SectionTitle } from '..';
import { SectionType } from '../../types';
import { PageSectionRouter } from '../PageSection';
import { EffectComponent } from '../layoutEffects';

type SectionProps = {
  item: SectionType;
  isVisible: boolean;
  index: number;
  scrollTop?: number;
}

export const Section: FC<SectionProps> = ({ item, index }) => {
  return (
    <EffectComponent
      effect={item.effect}
      effectOffset={item.effectOffset as number}
      elementsCount={item.content?.length}
    >
      <SectionBlock
        isVisible={true}
        fullWidth={item.fullWidth}
        index={index}
        marginTop={item.type !== 'BackgroundImage' ? '120px' : '0'}
      >
        <SectionTitle data={item} />
        <ButtonLinks buttons={item.buttons} />
        <EffectComponent>
          <PageSectionRouter data={item} type={item.type} />
        </EffectComponent>
      </SectionBlock>
    </EffectComponent>
  );
};
