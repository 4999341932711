import React from 'react';
import { Title, Subtitle, Block } from './SectionTitle.style';
import { SectionType } from '../../types';

interface SectionParams {
  data: SectionType;
}

export const SectionTitle: React.FC<SectionParams> = ({ data }) => {
  return (
    <Block>
      {data.title ? <Title>{data.title}</Title> : null}
      {data.subtitle ? <Subtitle>{data.subtitle}</Subtitle> : null}
    </Block>
  );
};
