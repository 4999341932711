import { FC, useEffect, useState, useCallback, useMemo } from 'react';
import { PageType, SectionType, SectionContent } from '../../../types';
import { Block, Buttons, PageSections, ContentContainer, SectionButtons } from './CMSPageSections.style';
import { FormButton, SmallButton } from '../../../components/styled';
import { Modal, DragAndDropList } from '../../../components';
import { PageSectionEditContent } from './PageSectionEditContent';

import { PageSectionRouter } from '../../../components/PageSection';

export const CMSPageSectionEdit: FC<{
  setEditPageSectionContent: (data: boolean) => void;
  onDropHandler: (data: SectionType[]) => void;
  saveSectionHandler: (data: string) => void;
  pageData?: PageType | null;
  editPageSectionIndex: number;
  reloadPageData: () => Promise<void>;
  modalOpened: boolean;
  setModalOpened: (status: boolean) => void;
}> = ({
  setEditPageSectionContent,
  onDropHandler,
  pageData,
  editPageSectionIndex,
  reloadPageData,
  modalOpened,
  setModalOpened,
}) => {
  const [sectionData, setSectionData] = useState<SectionType>();
  const [editSectionContent, setSectionContent] = useState<SectionContent>();
  const [contentArray, setContentArray] = useState<SectionContent[]>();
  const [editPageSectionContentIndex, setEditPageSectionContentIndex] = useState(-1);

  useEffect(() => {
    if (pageData && pageData.sections !== undefined) {
      setContentArray(pageData.sections[editPageSectionIndex].content as SectionContent[]);

      setSectionData(pageData.sections[editPageSectionIndex]);
    }
  }, [pageData, editPageSectionIndex]);

  const onDropContentHandler = useCallback(
    (sortedData: []) => {
      if (pageData && pageData.sections && pageData.sections[editPageSectionIndex]) {
        pageData.sections[editPageSectionIndex].content = sortedData as SectionContent[];

        onDropHandler(pageData.sections);
      }
    },
    [editPageSectionIndex, pageData, onDropHandler],
  );

  const saveSectionContentHandler = useCallback(
    (data: {}) => {
      if (pageData && pageData.sections) {
        let modifiedPageSections: SectionType[] = pageData.sections.map((item, index) => ({
          ...item,
          content: item.content ? [...(item.content as SectionContent[])] : [],
        }));

        if (editPageSectionContentIndex < 0) modifiedPageSections[editPageSectionIndex].content?.push(data);
        else modifiedPageSections[editPageSectionIndex].content?.splice(editPageSectionContentIndex, 1, data);

        onDropHandler(modifiedPageSections);

        setModalOpened(false);
      }
    },
    [pageData, editPageSectionContentIndex, editPageSectionIndex, setModalOpened, onDropHandler],
  );

  const sectionType = useMemo(() => (pageData?.sections ? pageData.sections[editPageSectionIndex].type : ''), [
    pageData,
    editPageSectionIndex,
  ]);

  const addContentHandler = useCallback(() => {
    setSectionContent({} as SectionContent);

    setEditPageSectionContentIndex(-1);
    setModalOpened(true);
  }, [setSectionContent, setEditPageSectionContentIndex, setModalOpened]);

  if (!pageData || !pageData.sections) return null;

  return (
    <>
      <Block>
        <Buttons>
          <FormButton layout="primary" onClick={() => setEditPageSectionContent(false)}>
            &laquo; Back
          </FormButton>
          <FormButton
            layout="primary"
            onClick={() => {
              addContentHandler();
            }}
          >
            Add content
          </FormButton>
        </Buttons>

        {!pageData ||
        pageData.sections === undefined ||
        pageData.sections[editPageSectionIndex] === undefined ? null : (
          <>
            <h2>{pageData.sections[editPageSectionIndex].title}</h2>

            <PageSections>
              <DragAndDropList
                items={
                  !contentArray || !contentArray.length
                    ? null
                    : contentArray.map((item, index) => {
                        return (
                          <>
                            <SectionButtons>
                              <SmallButton
                                layout="primary"
                                onClick={() => {
                                  setModalOpened(true);
                                  reloadPageData().then(() => {
                                    setSectionContent(item as SectionContent);
                                    setEditPageSectionContentIndex(index);
                                  });
                                }}
                              >
                                Edit
                              </SmallButton>
                            </SectionButtons>
                            <ContentContainer>
                              <PageSectionRouter
                                type={sectionType}
                                data={
                                  {
                                    ...sectionData,
                                    content: [item as SectionContent],
                                  } as SectionType
                                }
                              />
                            </ContentContainer>
                          </>
                        );
                      })
                }
                data={contentArray as []}
                onDragAndDrop={(itemid, index, data) => {
                  onDropContentHandler(data);
                }}
                enableDelete={true}
              />
            </PageSections>
          </>
        )}
      </Block>
      <Modal isOpen={modalOpened} setIsOpen={setModalOpened} onClose={() => {}}>
        <PageSectionEditContent
          sectionType={pageData.sections[editPageSectionIndex].type as string}
          editData={editSectionContent as SectionContent}
          callback={saveSectionContentHandler}
        />
      </Modal>
    </>
  );
};
