import React, { FC } from 'react';
import { SectionContainer, IconBoxContainer, Title, Text, Image, ImageContainer, Arrow } from './StepList.style';
import { SectionContent } from '../../../types';
import { IsVisible } from '../..';
import { IMAGE_PATH } from '../../../constants/config';

interface SectionParams {
  content: SectionContent[];
  offset?: number;
}

export const StepList: FC<SectionParams> = ({ content, offset = 0 }) => {
  return (
    <SectionContainer>
      {content.map((item, index) => {
        return (
          <IsVisible key={String(index)} index={index} offset={offset}>
            <IconBoxContainer>
              {!item.image ? null : (
                <ImageContainer>
                  <Image src={item.image.indexOf('/') > 0 ? item.image : IMAGE_PATH + item.image} />
                </ImageContainer>
              )}
              {!item.title ? null : <Title>{item.title}</Title>}
              {!item.text ? null : <Text>{item.text}</Text>}
            </IconBoxContainer>
            {index < content.length - 1 ? <Arrow /> : null}
          </IsVisible>
        );
      })}
    </SectionContainer>
  );
};
