const SERVER_ADMIN_URL = 'https://devocean.solutions/';

//const SERVER_ADMIN_URL = 'http://localhost:8080/';

export const SERVER_ADMIN = SERVER_ADMIN_URL;

export const DATA_DIR = 'data/';

export const SITE_NAME = 'DevOcean Solutions';

export const DATA_PATH = SERVER_ADMIN + 'data/';

export const IMAGE_PATH = SERVER_ADMIN + 'data/images/';

export const SITE_WIDTH_AS_NUMBER = 1120; 

export const SITE_MOBILE_WIDTH_AS_NUMBER = 768;

export const SITE_WIDTH = `${SITE_WIDTH_AS_NUMBER}px`;

export const FOOTER_COPYRIGHT = '© 2021 Devocean Solutions';
