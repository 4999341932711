import styled from 'styled-components';

export const Block = styled.div`
  & em.invisible {
    transform: translateX(-200%);
    transition: transform 0.6s ease;
  }
  & em.visible {
    transform: translateX(0%);
    transition: transform 0.6s ease;
  }
`;
