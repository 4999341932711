import { FC, useEffect } from 'react';
import { Login } from '.';
import { useAdminContext } from '../../context/AdminContext';
import { useCookies } from 'react-cookie';
import { api } from './Api';
import { BrowserRouter as AdminRouter, Switch, Route } from 'react-router-dom';
import { CMSDashboard, CMSNavigationEdit, CMSPages, CMSPageSections } from '../../screens/CMS';

export const CMSRouter: FC = () => {
  const [store, setStore] = useAdminContext();

  const [cookies, setCookie] = useCookies(['user', 'token']);

  useEffect(() => {
    //check if the saved token is still alive

    if (!store.token && cookies.user && cookies.token) {
      api({ do: 'cms', email: cookies.user, token: cookies.token }, (data) => {
        if (data.status === 'logged') {
          setStore({ email: cookies.user, token: cookies.token, status: 1 });
        } else {
          setCookie('token', '', {
            path: '/',
          });
        }
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (store.email) {
      if (store.email !== cookies.user || store.token !== cookies.token) {
        setCookie('user', store.email, {
          path: '/',
        });

        setCookie('token', store.token, {
          path: '/',
        });
      }
    }
  }, [store, setCookie, cookies]);

  if (!store.token) return <Login />;

  return (
    <AdminRouter>
      <Switch>
        <Route exact path="/C/M/S" component={CMSDashboard} />
        <Route exact path="/C/M/S/dashboard" component={CMSDashboard} />
        <Route exact path="/C/M/S/navigation" component={CMSNavigationEdit} />
        <Route exact path="/C/M/S/pages" component={CMSPages} />
        <Route exact path="/C/M/S/page/:url" component={CMSPageSections} />
      </Switch>
    </AdminRouter>
  );
};
