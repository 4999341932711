import styled from 'styled-components';
import { COLORS } from '../../../constants/colors';

export const Block = styled.div`
  @media (min-width: 768px) {
    padding: 30px 0 0 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1.5rem;
  }
  @media (max-width: 768px) {
    display: block;
  }
  & > em {
    background-color: ${COLORS.boxBackgroundColor};
    padding: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
  }
`;

export const Title = styled.div`
  color: ${COLORS.secondColor};
  font-size: 1.6rem;
  font-weight: bold;
`;

export const Text = styled.p`
  color: ${COLORS.sectionSubtitleColor};
  font-size: 1rem;
  line-height: 1.5rem;
  margin: 15px 0;
  padding-bottom: 15px;
  border-bottom: 1px ${COLORS.boxBorderColor} solid;
`;

export const PriceInfo = styled.div`
  margin-top: 5px;
  color: ${COLORS.sectionSubtitleColor};
  font-size: 1rem;
  & > span {
    font-weight: 700;
    color: ${COLORS.sectionTitleColor};
    font-size: 2.5rem;
  }
  &::first-letter {
    color: ${COLORS.sectionSubtitleColor};
    font-size: 2rem;
    font-weight: 500;
  }
`;
