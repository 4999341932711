import React, { FC, useCallback } from 'react';
import { IMAGE_PATH } from '../../constants/config';
import { Input } from '../styled/Form.style';
import { Img } from './FormField.style';
import { SmallButton } from '../styled';
import { api } from '../../components/CMSAdmin/Api';
import { useAdminContext } from '../../context/AdminContext';

export const UploadImageFile: FC<{
  enteredValue: string;
  setEnteredValue: (data: string) => void;
}> = ({ enteredValue, setEnteredValue }) => {
  const [store] = useAdminContext();

  const deleteImageHandler = () => {
    if (enteredValue.indexOf('://') > 0) setEnteredValue('');
    else {
      api(
        {
          do: 'cms',
          email: store.email,
          token: store.token,
          action: 'deleteImage',
          data: { filename: enteredValue },
        },
        (data) => {
          if (data.status === 'done') {
            setEnteredValue('');
          }
        },
      );
    }
  };

  const uploadImageHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      let filename = '';
      var reader = new FileReader();
      reader.onload = () => {
        const saveData = {
          filename: filename,
          data: reader.result,
        };

        api(
          {
            do: 'cms',
            email: store.email,
            token: store.token,
            action: 'saveImage',
            data: saveData,
          },
          (data) => {
            if (data.status === 'done') {
              setEnteredValue(filename);
            }
          },
        );
      };

      if (event.target && event.target.files && event.target.files.length) {
        if (event.target.files[0].type.substring(0, 6) === 'image/') {
          filename = event.target.files[0].name;
          reader.readAsDataURL(event.target.files[0]);
        }
      }
    },
    [setEnteredValue, store.email, store.token],
  );

  if (enteredValue) {
    return (
      <>
        <SmallButton layout="primary" onClick={() => deleteImageHandler()}>
          Delete image
        </SmallButton>
        <Img src={enteredValue.indexOf('://') > 0 ? enteredValue : IMAGE_PATH + enteredValue} alt="Preview" />
      </>
    );
  }

  return <Input type="file" required={false} onChange={(e) => uploadImageHandler(e)} />;
};
