import styled, { createGlobalStyle } from 'styled-components';
import { COLORS } from '../../../constants/colors';

export const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${COLORS.backgroundColor};
    max-width: 100%;
    min-height: 100%;
    margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  }
  html, #app, #root{
    min-height: 100vh;
    }
`;

export const Block = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AppLogo = styled.img`
  height: 80px;
  margin-top: -40px;
  position: absolute;
  top: 50%;
`;
