import styled from 'styled-components';
import { COLORS } from '../../constants/colors';
import { Link } from 'react-router-dom';

export const FooterContainer = styled.div`
  & {
    box-sizing: border-box;
    background-color: ${COLORS.backgroundColor};
    display: flex;
    width: 100%;
    max-width: 1120px;
    padding: 50px 20px 40px 20px;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }

  @media (max-width: 768px) {
    flex-direction: column;

  }
`;

export const LogoText = styled.div`
  color: ${COLORS.sectionTitleColor};
`;

export const LinksContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const FooterLink = styled(Link)`

  color: ${COLORS.sectionTitleColor};
  font-weight: normal;
  font-style: normal;
  margin: 0px 10px;
  display: inline-block;

  @media (min-width: 769px) {
    border-right: 1px solid ${COLORS.sectionTitleColor};
    padding-right: 20px;
    &:last-child {
      border: 0;
    }
  }
  @media (max-width: 768px) {
    margin: 10px;
  }
`;

export const SocialNetworks = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const A = styled.a`
  border-radius: 100%;
  background-color: ${COLORS.primaryColor};
  height: 30px;
  width: 30px;
  display: inline-block;
  padding: 7px;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 5px;
`;
