import { FC } from 'react';
import { Carousel, Zoom, ShowFromLeft, ShowFromRight, Blur, Opacity, ShowOnScroll } from '../layoutEffects';

export const contentDisplayEffects = [
  {
    label: 'none',
    value: ' ',
  },
  {
    label: 'Carousel',
    value: 'Carousel',
  },
  {
    label: 'Show from left',
    value: 'ShowFromLeft',
  },
  {
    label: 'Opacity',
    value: 'Opacity',
  },
  {
    label: 'Blur',
    value: 'Blur',
  },
  {
    label: 'Zoom',
    value: 'Zoom',
  },
];

export const EffectComponent: FC<{
  effect?: string;
  elementsCount?: number;
  effectOffset?: number;
}> = ({ children, effect = '', elementsCount = 0, effectOffset = 0 }) => {
  let Effect: FC = ({ children }) => <article>{children}</article>;

  switch (effect) {
    case 'Carousel':
      Effect = ({ children }) => <Carousel elementsCount={elementsCount}>{children}</Carousel>;
      break;
    case 'Zoom':
      Effect = ({ children }) => <Zoom>{children}</Zoom>;
      break;
    case 'ShowFromLeft':
      Effect = ({ children }) => <ShowFromLeft>{children}</ShowFromLeft>;
      break;
    case 'ShowFromRight':
      Effect = ({ children }) => <ShowFromRight>{children}</ShowFromRight>;
      break;
    case 'Blur':
      Effect = ({ children }) => <Blur>{children}</Blur>;
      break;
    case 'Opacity':
      Effect = ({ children }) => <Opacity>{children}</Opacity>;
      break;
    case 'ShowOnScroll':
      Effect = ({ children }) => (
        <ShowOnScroll elementsCount={elementsCount} elementHeight={effectOffset}>
          {children}
        </ShowOnScroll>
      );
      break;
  }

  return <Effect>{children}</Effect>;
};
