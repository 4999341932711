import styled from 'styled-components';

export const Block = styled.div`
  & em.invisible {
    transition: opacity 0.5s;
    opacity: 0.01;
  }
  & em.visible {
    transition: opacity 1s;
    opacity: 1;
  }
`;
