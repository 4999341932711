import { api } from '../../../components/CMSAdmin/Api';
import { NavigationProps } from '../../../types';
import { AdminStateType } from '../../../types';

export const UpdateNavigation = (
  changedData: NavigationProps,
  callback: (data: NavigationProps) => void,
  store: AdminStateType,
): void => {
  api({ do: 'cms', email: store.email, token: store.token, action: 'updateNavigation', data: changedData }, (data) => {
    if (data.status === 'done') {
      callback(changedData as NavigationProps);
    }
  });
};
