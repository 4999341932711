import { FC } from 'react';
import { PageItem, PageItemOptions } from './CMSPages.style';
import { SmallButton } from '../../../components/styled';

const PageItemElement: FC<{
  item: string;
  editDetailsHandler: (pageFile: string) => void;
  setEditPageSections: (pageFile: string) => void;
}> = ({ item, editDetailsHandler, setEditPageSections }) => {
  return (
    <PageItem>
      <b>{item.replace('.json', '')}</b>

      <PageItemOptions>
        <SmallButton layout="primary" onClick={() => editDetailsHandler(item)}>
          Edit
        </SmallButton>
        <SmallButton layout="primary" onClick={() => setEditPageSections(item)}>
          Content
        </SmallButton>
      </PageItemOptions>
    </PageItem>
  );
};

export default PageItemElement;
