import React, { FC } from 'react';
import { Block } from './CMSDashboard.style';
import { CMSNavigation } from '../../../components/CMSAdmin';

export const CMSDashboard: FC = () => {
  return (
    <>
      <CMSNavigation selectedLink="" />
      <Block>Hi</Block>
    </>
  );
};
