import styled from 'styled-components';
import { COLORS } from '../../../constants/colors';

export const Block = styled.div`
  margin-top: 27px;
  margin-left: auto;
  margin-right: auto;
  max-width: 900px;

  & > em {
    border-bottom: 1px ${COLORS.primaryColor} solid;
    display: block;
  }
  & > em:first-child {
    border-top: 1px ${COLORS.primaryColor} solid;
  }
`;

export const CareerBox = styled.section``;
export const Loading = styled.span``;

export const ToggleText = styled.div<{ opened: boolean }>`
  overflow: hidden;
  transform: scaleY(${(props) => (props.opened ? '1' : '0')});
  transform-origin: top;
  transition: transform 0.26s ease;
  height: ${(props) => (props.opened ? 'auto' : '0px')};
  padding: ${(props) => (props.opened ? '20px 0' : '0px')};

  & > button {
    margin-top: 20px;
  }
`;

export const Text = styled.div<{ opened: boolean }>`
  color: ${COLORS.sectionSubtitleColor};
  font-size: 1.1rem;
  line-height: 1.7rem;

  & > h1,
  > h2,
  > h3,
  > h4 {
    color: ${COLORS.secondColor};
    font-weight: 700;
    margin: 40px 0 20px 0;
  }
  & > h1:first-child,
  > h2:first-child,
  > h3:first-child,
  > h4:first-child {
    margin-top: 0;
  }
  & > h1 {
    font-size: 2rem;
    line-height: 1.25;
  }
  & > h2,
  > h3 {
    font-size: 1.5rem;
    line-height: 1.375;
    letter-spacing: -0.01em;
  }

  & > ul > li {
    padding-left: 20px;
  }

  & > ul > li::before {
    content: '•';
    color: ${COLORS.secondColor};
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
`;

export const ToggleIcon = styled.span<{ opened: boolean }>`
  display: block;
  margin-top: -16px;
  float: right;
  display: block;
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  border-radius: 100%;
  background: ${(props) => (props.opened ? COLORS.secondColor : 'none')};
  color: ${(props) => (props.opened ? COLORS.buttonText : COLORS.secondColor)};
  transform: rotate(${(props) => (props.opened ? '90' : '45')}deg);
  transition: 0.2s;
`;
export const Title = styled.div`
  color: ${COLORS.sectionTitleColor};
  font-size: 1.2rem;
  line-height: 0;
  font-weight: 500;
  padding: 30px 0;
  cursor: pointer;
  &:hover span {
    background: ${COLORS.secondColor};
    color: ${COLORS.buttonText};
  }
`;
