export interface CMSPageSectionFieldsType {
  sectionType: string;
  fields: {
    name: string;
    type: string;
    label: string;
    values?: string;
  }[];
}

export const cmsPageSectionFields: CMSPageSectionFieldsType[] = [
  {
    sectionType: 'ImageText',
    fields: [
      {
        name: 'title',
        type: 'text',
        label: 'Title',
      },
      {
        name: 'subtitle',
        type: 'text',
        label: 'Subtitle',
      },
      {
        name: 'image',
        type: 'imageFile',
        label: 'Image',
      },
      {
        name: 'css',
        type: 'text',
        label: 'Image css',
      },
      {
        name: 'text',
        type: 'richtext',
        label: 'Text',
      },
      {
        name: 'textPosition',
        type: 'select',
        label: 'Text position',
        values: 'right\nleft',
      },
    ],
  },
  {
    sectionType: 'BackgroundImage',
    fields: [
      {
        name: 'title',
        type: 'text',
        label: 'Title',
      },
      {
        name: 'subtitle',
        type: 'richtext',
        label: 'Subtitle',
      },
      {
        name: 'color',
        type: 'text',
        label: 'Text color',
      },
      {
        name: 'image',
        type: 'imageFile',
        label: 'Background Image',
      },
      {
        name: 'css',
        type: 'text',
        label: 'Image CSS',
      },
      {
        name: 'buttonLabel',
        type: 'text',
        label: 'Button label',
      },
      {
        name: 'buttonLink',
        type: 'text',
        label: 'Button link',
      },
    ],
  },
  {
    sectionType: 'InfoBox',
    fields: [
      {
        name: 'title',
        type: 'text',
        label: 'Title',
      },
      {
        name: 'text',
        type: 'textarea',
        label: 'Text',
      },
    ],
  },
  {
    sectionType: 'IconBox',
    fields: [
      {
        name: 'title',
        type: 'text',
        label: 'Title',
      },
      {
        name: 'text',
        type: 'textarea',
        label: 'Text',
      },
      {
        name: 'image',
        type: 'imageFile',
        label: 'Image file, url or data uri',
      },
    ],
  },
  {
    sectionType: 'StepList',
    fields: [
      {
        name: 'title',
        type: 'text',
        label: 'Title',
      },
      {
        name: 'text',
        type: 'textarea',
        label: 'Text',
      },
      {
        name: 'image',
        type: 'imageFile',
        label: 'Image file, url or data uri',
      },
    ],
  },
  {
    sectionType: 'Form',
    fields: [
      {
        name: 'formFieldLabel',
        type: 'text',
        label: 'Title',
      },
      {
        name: 'formFieldName',
        type: 'text',
        label: 'HTML Name',
      },
      {
        name: 'formFieldPlaceholder',
        type: 'text',
        label: 'Placeholder',
      },
      {
        name: 'formFieldType',
        type: 'select',
        label: 'Field type',
        values: 'text\ntextarea\ncheckbox\nselect\nnumeric\nemail\nimageFile\ndocumentFile',
      },
      {
        name: 'formFieldSize',
        type: 'select',
        label: 'Width',
        values: 'full\nhalf',
      },
      {
        name: 'formFieldDefaultValue',
        type: 'text',
        label: 'Default value',
      },
      {
        name: 'formFieldValues',
        type: 'textarea',
        label: 'Select options',
      },

      {
        name: 'formFieldRequired',
        type: 'checkbox',
        label: 'Required',
      },
    ],
  },
  {
    sectionType: 'Price',
    fields: [
      {
        name: 'title',
        type: 'text',
        label: 'Title',
      },
      {
        name: 'subtitle',
        type: 'text',
        label: 'Subtitle',
      },
      {
        name: 'price',
        type: 'text',
        label: 'Price',
      },
      {
        name: 'list',
        type: 'textarea',
        label: 'Benefits',
      },
    ],
  },
  {
    sectionType: 'Faq',
    fields: [
      {
        name: 'title',
        type: 'text',
        label: 'Question',
      },
      {
        name: 'text',
        type: 'textarea',
        label: 'Answer',
      },
    ],
  },
  {
    sectionType: 'Review',
    fields: [
      {
        name: 'title',
        type: 'text',
        label: 'Name',
      },
      {
        name: 'subtitle',
        type: 'text',
        label: 'Position',
      },
      {
        name: 'text',
        type: 'richtext',
        label: 'Feedback',
      },
      {
        name: 'image',
        type: 'imageFile',
        label: 'Avatar',
      },
    ],
  },
  {
    sectionType: 'Timeline',
    fields: [
      {
        name: 'title',
        type: 'text',
        label: 'Title',
      },
      {
        name: 'subtitle',
        type: 'text',
        label: 'Subtitle',
      },
      {
        name: 'text',
        type: 'richtext',
        label: 'Text',
      },
      {
        name: 'date',
        type: 'text',
        label: 'Year',
      },
      {
        name: 'textPosition',
        //type: 'textPosition',
        label: 'Text position',
        type: 'select',
        values: '\nLeft\nRight',
      },
    ],
  },
  {
    sectionType: 'Team',
    fields: [
      {
        name: 'title',
        type: 'text',
        label: 'Name',
      },
      {
        name: 'subtitle',
        type: 'text',
        label: 'Position',
      },
      {
        name: 'text',
        type: 'textarea',
        label: 'Text',
      },
      {
        name: 'image',
        type: 'imageFile',
        label: 'Avatar',
      },
    ],
  },
  {
    sectionType: 'Buttons',
    fields: [
      {
        name: 'title',
        type: 'text',
        label: 'Label',
      },
      {
        name: 'buttonStyle',
        type: 'select',
        label: 'Style',
        values: 'primary\ndefualt',
      },
      {
        name: 'linkTo',
        type: 'text',
        label: 'Link to',
      },
    ],
  },
  {
    sectionType: 'Career',
    fields: [
      {
        name: 'title',
        type: 'text',
        label: 'Title',
      },
      {
        name: 'text',
        type: 'richtext',
        label: 'Text',
      },
    ],
  },
  {
    sectionType: 'Gallery',
    fields: [
      {
        name: 'title',
        type: 'text',
        label: 'Title',
      },
      {
        name: 'image',
        type: 'imageFile',
        label: 'Image',
      },
      {
        name: 'css',
        type: 'text',
        label: 'Image CSS',
      },
    ],
  },
];
