import { FC, useEffect, useState, useCallback } from 'react';
import { Block } from './CMSPages.style';
import { CMSNavigation } from '../../../components/CMSAdmin';
import { Modal, Form } from '../../../components';
import { FormButton } from '../../../components/styled';
import { useAdminContext } from '../../../context/AdminContext';
import { api } from '../../../components/CMSAdmin/Api';
import { PageType } from '../../../types';
import PageItemElement from './PageItemElement';
import { saveButtonHandler, deleteButtonHandler, editDetailsHandler } from './ButtonHandlers';
import { CMSPageSections } from '../CMSPageSections';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const blankDataState = {
  previewImage: '',
  url: '',
  browserTitle: '',
  metaDescription: '',
  sections: [],
};

export const CMSPages: FC = () => {
  const [store] = useAdminContext();
  const [modelOpened, setModalOpened] = useState(false);
  const [existingPages, setExistingPages] = useState([]);
  const [editPageFile, setEditPageFile] = useState('');
  const [editPage, setEditPage] = useState<PageType>(blankDataState);
  const [editPageSections, setEditPageSections] = useState('');

  const fetchPages = useCallback(() => {
    api(
      {
        do: 'cms',
        email: store.email,
        token: store.token,
        action: 'getExistingPages',
      },
      (data) => {
        if (data.status === 'done') {
          setExistingPages(data.pages as []);
        }
      },
    );
  }, [store.email, store.token]);

  useEffect(() => {
    fetchPages();
  }, [fetchPages]);

  useEffect(() => {
    setModalOpened(false);
  }, [existingPages]);

  const AddNewLinkHandler = useCallback(() => {
    setEditPageFile('');
    setEditPage(blankDataState);
    setModalOpened(true);
  }, []);

  return (
    <>
      <CMSNavigation selectedLink="/c/m/s/pages" />
      {editPageSections ? (
        <CMSPageSections editPageFile={editPageSections} setEditPageSections={setEditPageSections} />
      ) : (
        <Block>
          <FormButton layout="primary" onClick={AddNewLinkHandler}>
            Add page
          </FormButton>

          {existingPages.map((item, index) => (
            <PageItemElement
              editDetailsHandler={() => editDetailsHandler(setEditPageFile, setEditPage, setModalOpened, item, store)}
              key={String(index)}
              item={item}
              setEditPageSections={setEditPageSections}
            />
          ))}
        </Block>
      )}
      <Modal isOpen={modelOpened} setIsOpen={setModalOpened}>
        <Form
          formData={{
            buttonLabel: 'Save',
            secondButtonLabel: editPageFile ? 'Delete' : '',
            secondButtonStyle: editPageFile ? 'delete' : '',
            secondButtonCallback: () => deleteButtonHandler(editPageFile, store, fetchPages),
            fields: [
              {
                formFieldLabel: 'Image',
                formFieldName: 'previewImage',
                formFieldType: 'imageFile',
                formFieldDefaultValue: editPage.previewImage,
              },
              {
                formFieldLabel: 'URL',
                formFieldName: 'url',
                formFieldType: 'text',
                formFieldDefaultValue: editPage.url,
              },
              {
                formFieldLabel: 'Browser title',
                formFieldName: 'browserTitle',
                formFieldType: 'text',
                formFieldDefaultValue: editPage.browserTitle,
              },
              {
                formFieldLabel: 'Meta description',
                formFieldName: 'metaDescription',
                formFieldType: 'text',
                formFieldDefaultValue: editPage.metaDescription,
              },
              {
                formFieldLabel: 'Navigation position',
                formFieldName: 'navigationPosition',
                formFieldType: 'numeric',
                formFieldDefaultValue: String(editPage.navigationPosition) || '0',
              },
              {
                formFieldLabel: 'Footer position',
                formFieldName: 'footerPosition',
                formFieldType: 'numeric',
                formFieldDefaultValue: String(editPage.footerPosition) || '0',
              },
            ],
          }}
          callBack={(data) =>
            saveButtonHandler({ ...editPage, ...data }, existingPages as [], editPageFile, store, fetchPages)
          }
        />
      </Modal>
      <ToastContainer />
    </>
  );
};
