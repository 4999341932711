import React, { FC } from 'react';
import { Block, Title, Text } from './InfoBox.style';
import { SectionContent } from '../../../types';
import { IsVisible } from '../../';

interface SectionParams {
  content: SectionContent[];
  offset?: number;
}

export const InfoBox: FC<SectionParams> = ({ content, offset = 0 }) => {
  return (
    <Block>
      {content.map((item, index) => {
        return (
          <IsVisible key={String(index)} index={index} offset={offset}>
            <Title>{item.title}</Title>
            <Text>{item.text}</Text>
          </IsVisible>
        );
      })}
    </Block>
  );
};
