import { createGlobalStyle } from 'styled-components';
import { COLORS } from '../../constants/colors';

export const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${COLORS.backgroundColor};
    max-width: 100%;
    margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  }
  a {
    color: ${COLORS.linkColor};
    font-weight: bold;
    text-decoration: none;
    font-style: italic;
    &:hover {
      color: ${COLORS.secondColor};
    }
  }
`;
