import styled, { css } from 'styled-components';
import { COLORS } from '../../constants/colors';

const borderStyle = css`
  border: 1px dashed gray;
  margin: 10px 10px;
  padding: 10px;
`;

export const DraggableItems = styled.div<{ dragactive: boolean }>`
  transform: scale(${(props) => (props.dragactive ? '0.5' : '1')});
  transition: transform 0.2s;
  transform-origin: top;
  cursor: ${(props) => (props.dragactive ? 'grabbing' : 'auto')};
`;

export const DeleteElementContainer = styled.div`
  position: fixed;
  bottom: 50px;
  left: 50px;

  & > div {
    border-radius: 100%;
    margin: 0;
    height: 80px;
    width: 80px;
    text-align: center;
    padding-top: 1.2rem;
    box-sizing: border-box;
    font-size: 2rem;
  }
`;

export const DraggableItem = styled.div`
  user-select: none;
  text-align: center;
  ${borderStyle};
  cursor: grab;
`;

export const DragOver = styled.div<{ dragover: boolean; dragactive: boolean }>`
  display: ${(props) => (props.dragactive ? 'block' : 'none')};
  background: ${(props) => (props.dragover ? COLORS.primaryColor : 'none')};
  height: ${(props) => (props.dragover ? '80px' : '40px')};
  ${borderStyle};
  opacity: 0.3;
  transition: 0.2s;
`;
