import { SERVER_ADMIN } from '../../constants/config';

interface ApiReturnData {
  status: string;
  alert?: string;
  log?: string;
  error?: string;
  token?: string;
  message?: string;
  pages?: [];
  items?: [];
  item?: string;
  data?: {};
}

interface ApiRequestData {
  do?: string;
  action?: string;
  email?: string;
  token?: string;
  data?: {};
  page?: string;
  item?: string;
}

export const api = async (requestData: ApiRequestData, returnData: (data: ApiReturnData) => void) => {
  try {
    const server = SERVER_ADMIN + requestData.do;

    await fetch(server, {
      method: 'POST',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify({
        ...requestData,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((data: ApiReturnData) => {
        if (data && typeof data === 'object') {
          if (data.alert) alert(data.alert);

          if (data.log) console.log(data.log);

          if (
            data.status === 'logout' &&
            window.location.href.substring(window.location.href.length - 6) !== '/c/m/s'
          ) {
            window.location.href = '/c/m/s';
          }

          returnData(data);
        }
      });
  } catch (e) {
    console.log(e);
  }
};
