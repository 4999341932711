import React from 'react';
import { ButtonsContainer } from './ButtonLinks.style';
import { Button } from '../../styled';
import { ButtonType } from '../../../types';

interface SectionParams {
  buttons: ButtonType[];
}

export const ButtonLinks: React.FC<SectionParams> = ({ buttons }) => {
  if (!buttons) return <></>;

  return (
    <ButtonsContainer>
      {buttons.map((item, index) => (
        <Button key={String(index)} layout={item.style ? item.style : 'default'} to={`/${item.url}`}>
          {item.title}
        </Button>
      ))}
    </ButtonsContainer>
  );
};
