import { FC, useState, useEffect } from 'react';
import { SectionContent, FormFieldType, SectionForm } from '../../../types';
import { Form } from '../../../components';
import { cmsPageSectionFields } from '../../../components/PageSection/PageSectionRouter/cmsPageSectionFields';

interface PageSectionAdminParams {
  sectionType: string;
  editData: SectionContent;
  callback: (data: {}) => void;
}

export const PageSectionEditContent: FC<PageSectionAdminParams> = ({ sectionType, editData, callback }) => {
  const [formData, setFormData] = useState<SectionForm>();

  useEffect(() => {
    const sectionContentFields = cmsPageSectionFields.find((item) => item.sectionType === sectionType);

    let sectionFormData: SectionForm = {
      buttonLabel: 'Save',
      fields: [],
    };

    sectionContentFields?.fields.map((item) => {
      let fieldData: FormFieldType = {
        formFieldLabel: item.label,
        formFieldName: item.name,
        formFieldType: item.type,
        formFieldValues: item.values,
      };

      if (editData) {
        let defaultValue = '';

        Object.entries(editData).map((val, index) => {
          if (val[0] === item.name) {
            defaultValue = val[1];
          }

          return null;
        });

        fieldData.formFieldDefaultValue = defaultValue;
      }

      if (!sectionFormData.fields) sectionFormData.fields = [];

      sectionFormData.fields.push(fieldData);
      return null;
    });

    setFormData(sectionFormData);
  }, [sectionType, editData]);

  if (!formData) return null;

  return <Form formData={formData} callBack={callback} />;
};
