import styled from 'styled-components';
import { COLORS } from '../../../constants/colors';

export const Block = styled.div`
  padding: 30px 0;
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(4, 1fr);
  @media (max-width: 900px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0rem;
    & div {
      font-size: 0.9rem;
    }
  }
  & > em {
    text-align: center;
  }
`;

export const TeamContainer = styled.div`
  text-align: center;
`;

export const Avatar = styled.img<{ src: string | undefined }>`
  height: 120px;
  width: 120px;
  border-radius: 100%;
  margin: 10px;
`;

export const Text = styled.div`
  color: ${COLORS.secondColor};
  font-size: 1rem;
  line-height: 1.4rem;
  margin: 15px 0 0 0;
  padding-bottom: 20px;
  margin-bottom: 8px;
`;

export const Name = styled.div`
  color: ${COLORS.sectionTitleColor};
  font-size: 1.3rem;
  font-weight: 500;
  margin-right: 15px;
  margin-bottom: 8px;
`;
export const Position = styled.div`
  color: ${COLORS.sectionSubtitleColor};
  font-size: 1rem;
  margin-bottom: 8px;
`;
