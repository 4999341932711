import { FC } from 'react';
import { FOOTER_COPYRIGHT } from '../../constants/config';
import { FooterContainer, LogoText, FooterLink, SocialNetworks, A, LinksContainer } from './Footer.style';
import IconMail from '../../assets/tep_icon_mail.svg';
import IconLinkedin from '../../assets/tep_icon_linkedin.svg';
import IconFacebook from '../../assets/tep_icon_facebook.svg';
import SVG from 'react-inlinesvg';

import { PAGES } from '../../constants/pages';
import { NavigationLink } from '../../types';

const footerLinks: NavigationLink[]  = [
  PAGES.privacyPolicy,
  PAGES.cookies,
  PAGES.contacts
];

export const Footer: FC = () => {

  return (
    <FooterContainer>
      <LogoText>{FOOTER_COPYRIGHT}</LogoText>

      <LinksContainer>
        {footerLinks.map((item, index) => (
              <FooterLink key={`${item.title}-${index}`} to={item.linkTo}>
                {item.title}
              </FooterLink>
            ))}
      </LinksContainer>
      
      <SocialNetworks>
        <A href="mailto:admin@devocean.solutions">
          <SVG src={IconMail} />
        </A>
        <A href="https://www.linkedin.com/company/devocean-solutions/" target="_blank">
          <SVG src={IconLinkedin} />
        </A>
        <A href="https://www.facebook.com/DevOceanSolutions" target="_blank">
          <SVG src={IconFacebook} />
        </A>
      </SocialNetworks>
    </FooterContainer>
  );
};
