import styled from 'styled-components';
import { COLORS } from '../../../constants/colors';

export const Block = styled.div`
  margin-bottom: 100px;
  @media (min-width: 768px) {
    padding: 10px 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
  }
  @media (max-width: 768px) {
    display: block;
  }
  & > em {
    display: block;
    @media (max-width: 768px) {
      padding: 20px 0px;
      margin: 0 20px;
    }
  }
`;

export const FaqBox = styled.div`
  @media (max-width: 768px) {
    padding: 20px 0px;
    margin: 0 20px;
  }
`;

export const Title = styled.div`
  color: ${COLORS.sectionTitleColor};
  font-size: 1.2rem;
  font-weight: 500;
`;

export const Text = styled.p`
  color: ${COLORS.sectionSubtitleColor};
  font-size: 1.1rem;
  line-height: 1.7rem;
  margin: 15px 0;
`;
