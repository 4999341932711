import { FC } from "react";
import { DragOver } from "./DragAndDropList.style";

interface PropsTypes {
  index: number;
  dragElementOver: number;
  setDragElementOver: (index: number) => void;
  onDropOverHandler: (event: React.DragEvent<HTMLDivElement>) => void;
  setDragElement: (index: number) => void;
  dragActive: boolean;
}

const DragOverContainer: FC<PropsTypes> = ({
  index,
  children,
  dragElementOver,
  setDragElementOver,
  onDropOverHandler,
  dragActive,
}) => {
  return (
    <DragOver
      dragover={dragElementOver === index + 1}
      dragactive={dragActive}
      onDragOver={(event) => {
        event.preventDefault();
        setDragElementOver(index + 1);
      }}
      onDragLeave={(event) => {
        event.preventDefault();
        setDragElementOver(-1);
      }}
      onDrop={(event) => {
        event.preventDefault();
        onDropOverHandler(event);
      }}
    >
      {children}
    </DragOver>
  );
};

export default DragOverContainer;
