import styled from 'styled-components';
import { COLORS } from '../../../constants/colors';

export const Block = styled.div`
  max-width: 100%;
  height: 100%;
  width: 960px;
  margin: auto;
  text-align: center;
  color: ${COLORS.textColor};

  & > div section {
    zoom: 0.5;
    max-width: 100%;
    width: auto;
  }
`;

export const ContentContainer = styled.div`
  zoom: 0.5;
  max-width: 100%;
  width: auto;
  padding-top: 20px;

  & > div:first-of-type {
    width: auto;
    all: initial;
  }
`;

export const PageSections = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
`;

export const SectionLabel = styled.div`
  color: ${COLORS.primaryColor};
  font-size: 1.2rem;
`;

export const Buttons = styled.div`
  & > button {
    margin: 20px 10px;
  }
`;

export const NavItem = styled.div`
  color: ${COLORS.textColor};
  background: ${COLORS.backgroundColor};
  text-align: center;
  border: 1px solid ${COLORS.textColor};
  margin: 0 10px;
  padding: 20px;
  cursor: move;
`;

export const SectionButtons = styled.div`
  display: flex;
  justify-content: space-between;
`;
