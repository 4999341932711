import { FC, useEffect } from 'react';
import '../../static/redactor-3-4-11/redactor.css';
// eslint-disable-next-line
import Redactor from '../../static/redactor-3-4-11/redactor.js';
// eslint-disable-next-line
import '../../static/redactor-3-4-11/plugins/fontcolor.js';
import '../../static/redactor-3-4-11/plugins/alignment/alignment';
import '../../static/redactor-3-4-11/plugins/imagemanager/imagemanager';
import '../../static/redactor-3-4-11/plugins/inlinestyle/inlinestyle';
import { COLORS } from '../../constants/colors';

const siteColors = Object.entries(COLORS).map((k, v) => {
  return k[1];
});

export const RichText: FC<{ value: string; setValue: (data: string) => void }> = ({ value, setValue }) => {
  useEffect(() => {
    Redactor('#textarea', {
      callbacks: {
        blur: function () {
          setValue(Redactor('#textarea', 'source.getCode'));
        },
      },
      plugins: ['fontcolor', 'alignment', 'imagemanager', 'inlinestyle'],
      fontcolors: siteColors,
    });
    Redactor('#textarea', 'source.setCode', value);
  }, [setValue, value]);

  return <textarea id="textarea"></textarea>;
};
