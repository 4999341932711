import styled from 'styled-components';

export const Block = styled.div`
  & em.invisible {
    transform: scale(0.1);
    transition: transform 0.6s ease;
  }
  & em.visible {
    transform: scale(1);
    transition: transform 0.6s ease;
  }
`;
