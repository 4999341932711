import styled from 'styled-components';
import { COLORS } from '../../constants/colors';

export const ListTitle = styled.div`
  color: ${COLORS.sectionTitleColor};
  margin-bottom: 10px;
`;

export const ListItems = styled.div`
  flex: 1;
  padding-bottom: 20px;
`;

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
`;

export const ListItem = styled.div`
  color: ${COLORS.sectionSubtitleColor};
  line-height: 2rem;
  padding-left: 20px;
  background-image: url('data:image/svg+xml;utf8,<svg class="cr rc fj ld sc rn" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><g fill="${COLORS.successGreen}"><path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z"></path></g></svg>');
  background-size: 12px;
  background-repeat: no-repeat;
  background-position: left 10px;
`;
