import { FC } from 'react';
import { Block, Image, Figure, TopBlock } from './Gallery.style';
import { SectionContent } from '../../../types';
import { IMAGE_PATH } from '../../../constants/config';
interface SectionParams {
  content: SectionContent[];
  offset?: number;
}

const imgsize = [
  { width: '41.67%', maxWidth: '320px', left: '0', top: '45%' },
  { width: '78.13%', maxWidth: '600px', left: '10%', top: '0' },

  { width: '25.52%', maxWidth: '196px', right: '20%', bottom: '0' },
  { width: '32.55%', maxWidth: '250px', right: '0', top: '8.5%' },
];

export const Gallery: FC<SectionParams> = ({ content, offset = 0 }) => {
  return (
    <TopBlock>
      <Block>
        {content.map((item, index) => {
          return (
            <Figure
              key={index}
              width={imgsize[index].width}
              maxWidth={imgsize[index].maxWidth}
              left={imgsize[index].left}
              right={imgsize[index].right}
              top={imgsize[index].top}
              bottom={imgsize[index].bottom}
              transition={index}
            >
              <Image src={item.image && item.image.indexOf('://') > 0 ? item.image : IMAGE_PATH + item.image} />
            </Figure>
          );
        })}
      </Block>
    </TopBlock>
  );
};
