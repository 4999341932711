import styled from 'styled-components';

export const Block = styled.div`
  & em.invisible {
    filter: blur(10px);
    transition: 0.1s;
  }
  & em.visible {
    filter: blur(0px);
    transition: 0.1s;
  }
`;
