import { FC, useEffect, useState, useCallback } from 'react';
import { Block, NavItem } from './CMSNavigationEdit.style';
import { CMSNavigation } from '../../../components/CMSAdmin';
import { Modal } from '../../../components/Modal';
import { FormButton } from '../../../components/styled';
import { useAdminContext } from '../../../context/AdminContext';
import { DragAndDropList, Form } from '../../../components';
import { NavigationProps, LinkType } from '../../../types';
import { UpdateNavigation } from './UpdateNavigation';
import { NavigationLink } from './types';
import { api } from '../../../components/CMSAdmin/Api';

const initialState = { title: '', url: '', id: -1 };

export const CMSNavigationEdit: FC = () => {
  const [store] = useAdminContext();
  const [modelOpened, setModalOpened] = useState(false);
  const [navigationData, setNavigationData] = useState<NavigationProps>();
  const [editLink, setEditLink] = useState<NavigationLink>(initialState);

  useEffect(() => {
    api({ do: 'cms', email: store.email, token: store.token, action: 'getNavigation', page: 'navigation' }, (data) => {
      if (data.status === 'done') {
        setNavigationData(data.data as NavigationProps);
      }
    });
  }, [store.email, store.token]);

  useEffect(() => {
    setModalOpened(false);
  }, [navigationData]);

  const editLinkButtonHandler = useCallback(
    (newData: LinkType) => {
      let newNavigationLinks: LinkType[] = [];
      if (navigationData) {
        //insert a new record
        if (editLink.id < 0) {
          newNavigationLinks = navigationData.links;
          newNavigationLinks.push({ ...newData });
        } else {
          //edit record
          newNavigationLinks = navigationData.links.map((item, index) => {
            if (index + 1 === editLink.id) return { ...newData };
            return item;
          });
        }

        const changedData = {
          links: newNavigationLinks,
          buttons: navigationData.buttons ? navigationData.buttons : [],
        };

        UpdateNavigation(changedData as NavigationProps, setNavigationData, store);
      }
    },
    [editLink.id, navigationData, store],
  );

  const DragDropListHandler = useCallback(
    (elementIndex: number, newPositionIndex: number, changedData: []) => {
      if (navigationData) {
        const changedNavigationData = {
          links: changedData,
          buttons: navigationData.buttons,
        } as NavigationProps;

        UpdateNavigation(changedNavigationData, setNavigationData, store);
      }
    },
    [navigationData, store],
  );

  const AddNewLinkHandler = useCallback(() => {
    setEditLink(initialState);
    setModalOpened(true);
  }, []);

  return (
    <>
      <CMSNavigation selectedLink="/c/m/s/navigation" />
      <Block>
        <FormButton layout="primary" onClick={AddNewLinkHandler}>
          Add new link
        </FormButton>

        <DragAndDropList
          items={
            !navigationData
              ? null
              : navigationData.links.map((item, index) => (
                  <NavItem
                    onDoubleClick={() => {
                      setEditLink({
                        title: item.title,
                        url: item.url,
                        id: index + 1,
                      });
                      setModalOpened(true);
                    }}
                  >
                    <b>{item.title}</b>
                    <br />
                    {item.url}
                  </NavItem>
                ))
          }
          data={!navigationData || !navigationData.links.length ? null : (navigationData.links as [])}
          onDragAndDrop={DragDropListHandler}
          enableDelete={true}
        />
      </Block>
      <Modal isOpen={modelOpened} setIsOpen={setModalOpened}>
        <Form
          formData={{
            buttonLabel: 'Save',
            fields: [],
          }}
          content={[
            {
              formFieldLabel: 'Link title',
              formFieldName: 'title',
              formFieldType: 'text',
              formFieldDefaultValue: editLink.title,
            },
            {
              formFieldLabel: 'URL',
              formFieldName: 'url',
              formFieldType: 'text',
              formFieldDefaultValue: editLink.url,
            },
          ]}
          callBack={(data) => editLinkButtonHandler(data as LinkType)}
        />
      </Modal>
    </>
  );
};
