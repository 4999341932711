import React from 'react';
import { Block, Text, Name, Position, Avatar } from './Team.style';
import { SectionContent } from '../../../types';
import { IMAGE_PATH } from '../../../constants/config';
import { IsVisible } from '../../';

interface SectionParams {
  content: SectionContent[];
  offset?: number;
}

export const Team: React.FC<SectionParams> = ({ content, offset = 0 }) => {
  return (
    <Block>
      {content.map((item, index) => {
        return (
          <IsVisible key={String(index)} index={index} offset={offset}>
            <Avatar src={item.image && item.image.indexOf('://') > 0 ? item.image : IMAGE_PATH + item.image} />
            <Name>{item.title}</Name>
            <Position>{item.subtitle}</Position>
            <Text>{item.text}</Text>
          </IsVisible>
        );
      })}
    </Block>
  );
};
