import { api } from '../../../components/CMSAdmin/Api';
import { PageType } from '../../../types';
import { AdminStateType } from '../../../types';
import { toast } from 'react-toastify';

export const saveButtonHandler = (
  editPage: PageType,
  existingPages: [],
  editPageFile: string,
  store: AdminStateType,
  fetchPages: () => void,
) => {
  if (!editPage.url) {
    toast('Please enter a page URL.');
    return false;
  }

  if (!editPageFile) {
    //check the url of the new page if already exists
    if (existingPages.find((item) => item === editPage.url)) {
      toast('Already exists page with the same URL.');
      return false;
    }
  }

  api(
    {
      do: 'cms',
      email: store.email,
      token: store.token,
      action: 'updatePage',
      data: editPage,
      page: editPageFile,
    },
    (data) => {
      if (data.status === 'done') {
        fetchPages();
      }
    },
  );
};

export const deleteButtonHandler = (editPageFile: string, store: AdminStateType, fetchPages: () => void) => {
  if (editPageFile && window.confirm('Are you sure?')) {
    api(
      {
        do: 'cms',
        email: store.email,
        token: store.token,
        action: 'deletePage',
        page: editPageFile,
      },
      (data) => {
        if (data.status === 'done') {
          fetchPages();
        }
      },
    );
  }
};

export const editDetailsHandler = (
  setEditPageFile: (data: string) => void,
  setEditPage: (data: PageType) => void,
  setModalOpened: (data: boolean) => void,
  pageFile: string,
  store: AdminStateType,
) => {
  api(
    { do: 'cms', email: store.email, token: store.token, action: 'getDataCMS', page: 'pages/' + pageFile },
    (data) => {
      if (data.status === 'done') {
        setEditPageFile(pageFile);
        setEditPage(data.data as PageType);
        setModalOpened(true);
      }
    },
  );
};
