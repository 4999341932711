import styled from 'styled-components';
import { COLORS } from '../../../constants/colors';

export const Block = styled.div`
  & > div:last-child {
    border: 0;
  }
  @media (min-width: 768px) {
    padding: 30px 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2rem;
  }
  @media (max-width: 768px) {
    display: block;
  }
  & > em {
    background-color: ${COLORS.boxBackgroundColor};

    @media (min-width: 768px) {
      padding: 0 20px;
    }

    @media (max-width: 768px) {
      padding: 20px 0px;
      margin: 0 20px;
    }
  }
`;

export const Title = styled.div`
  padding: 12px 0 20px 0;
`;

export const Avatar = styled.div<{ src: string | undefined }>`
  height: 48px;
  width: 48px;
  margin: 20px 0 10px 0;
  border-radius: 100%;
  background: ${(props) => (props.src ? 'url(' + props.src + ')' : 'none')};
  background-size: contain;
  opacity: 0.6;
  &::after {
    content: '"';
    position: absolute;
    font-size: 4rem;
    color: ${COLORS.secondColor};
    margin-left: 30px;
    margin-top: -15px;
    font-style: italic;
  }
`;

export const Text = styled.p`
  color: ${COLORS.sectionSubtitleColor};
  font-size: 0.9rem;
  line-height: 1.4rem;
  margin: 15px 0 0 0;
  padding-bottom: 20px;
  border-bottom: 1px ${COLORS.boxBorderColor} solid;
`;

export const Name = styled.span`
  color: ${COLORS.sectionTitleColor};
  font-size: 0.85rem;
  font-weight: 500;
  margin-right: 15px;
`;
export const Position = styled.span`
  color: ${COLORS.secondColor};
  font-size: 0.85rem;
`;
