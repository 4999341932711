import React from 'react';
import { AdminStateType } from '../types';

const initialState: AdminStateType = {
  email: '',
  token: '',
  status: 0,
};

// Global app context.
type StateT = typeof initialState;
type StateGetSetT = [StateT, React.Dispatch<React.SetStateAction<StateT>>];

const AdminContext = React.createContext<StateGetSetT | undefined>(undefined);

// Context provider, should wrap entire application.
type AdminContextProviderProps = {
  children: React.ReactNode;
};

const AdminContextProvider: React.FC<AdminContextProviderProps> = ({ children }) => {
  const contextGetSet = React.useState(initialState);
  return <AdminContext.Provider value={contextGetSet}>{children}</AdminContext.Provider>;
};

// Custom hook to retrieve and set context state.
type SetPartialStateT = (newVals: Partial<StateT>) => void;
type UseMyContextT = [StateT, SetPartialStateT];

const useAdminContext = (): UseMyContextT => {
  const [state, setState] = React.useContext(AdminContext) as StateGetSetT;
  const setPartialState = (newVals: Partial<StateT>) => {
    setState({ ...state, ...newVals });
  };
  return [state, setPartialState];
};

export { AdminContextProvider, useAdminContext };
