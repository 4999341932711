import styled from 'styled-components';
import { COLORS } from '../../../constants/colors';

export const Block = styled.div`
  text-align: center;
  & > em:last-child div {
    border: 0;
  }
  @media (min-width: 900px) {
    padding-bottom: 30px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2rem;

    & > :nth-child(3n) :after {
      display: none;
    }
  }

  @media (min-width: 768px) and (max-width: 900px) {
    padding: 30px 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;

    & > :nth-child(2n) :after {
      display: none;
    }
  }
  @media (max-width: 767px) {
    & :after {
      display: none;
    }
  }
`;

export const IconBoxContainer = styled.div`
  position: relative;
  @media (min-width: 768px) {
    padding: 0 20px;
  }

  @media (max-width: 768px) {
    padding: 20px 0px;
    margin: 0 20px;
    border-bottom: 1px ${COLORS.boxBorderColor} solid;
  }
  & > svg {
    padding: 15px;
    border-radius: 100%;
    background-color: ${COLORS.primaryColor};
    color: ${COLORS.buttonText};
    margin-bottom: 12px;
  }
  &:after {
    content: '';
    top: 35px;
    position: absolute;
    width: 70%;
    border-bottom: 1px dashed #ffffff30;
    right: 0%;
    left: 70%;
  }
`;

export const Title = styled.div`
  color: ${COLORS.sectionTitleColor};
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 8px;
  @media (max-width: 600px) {
    font-size: 1.2rem;
  }
`;

export const Text = styled.p`
  color: ${COLORS.textColor};
  font-size: 1.1rem;
  line-height: 1.5rem;
  margin: 15px 0 0 0;

  @media (max-width: 600px) {
    font-size: 1rem;
  }
`;

export const ImageContainer = styled.div`
  border-radius: 100%;
  background-color: ${COLORS.primaryColor};
  padding: 20px;
  height: 30px;
  width: 30px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
`;

export const Image = styled.img`
  filter: ${COLORS.iconColorCssFilter};
  width: 25px;
`;
