import { Page } from './screens';
import { CMSAdmin } from './screens/CMS/CMSAdmin';

import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

const App = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Page} />
        <Route exact path="/C/M/S" component={CMSAdmin} />
        <Route exact path="/C/M/S/:url" component={CMSAdmin} />
        <Route exact path="/:url" component={Page} />
        <Redirect to="/" />
      </Switch>
    </Router>
  );
};

export default App;
