import React from 'react';
import sanitizeHtml from 'sanitize-html';
import { Block, Title, Text, PriceInfo } from './Price.style';
import { List } from '../..';
import { ButtonType } from '../../../types';
import { SectionContent } from '../../../types';
import { IsVisible } from '../../';

interface SectionParams {
  content: SectionContent[];
  offset?: number;
}

export const Price: React.FC<SectionParams> = ({ content, offset = 0 }) => {
  return (
    <Block>
      {content.map((item, index) => {
        return (
          <IsVisible key={String(index)} index={index} offset={offset}>
            <Title>{item.title}</Title>
            {!item.price ? null : (
              //changes the style of the numbers
              <PriceInfo
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(item.price.replace(/(\$?\d+)/g, '<span>$1</span>')),
                }}
              ></PriceInfo>
            )}
            <Text>{item.subtitle}</Text>
            <List title={item.listTitle as string} items={item.list as []} button={item.button as ButtonType} />
          </IsVisible>
        );
      })}
    </Block>
  );
};
