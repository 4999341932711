import styled from 'styled-components';
import { COLORS } from '../../../constants/colors';

export const Block = styled.div`
  & > em {
    display: flex;
    align-items: center;
    justify-content: space-around;
    background: linear-gradient(rgba(0, 0, 0), transparent);
  }
`;

export const Image = styled.img<{ size: string; css?: string }>`
  width: 100%;
  max-height: 100vh;
  object-fit: cover;
  min-height: 300px;
  ${(props) => (props.css ? props.css : '')}
`;

export const TextContent = styled.div<{ color?: string }>`
  position: absolute;
  text-align: center;
  color: ${COLORS.textColor};
  font-size: 1.1rem;
  line-height: 1.5rem;
  padding: 10px;
  box-sizing: border-box;
  flex: 1;
  @media (max-width: 768px) {
    width: 100%;
  }
  & > div {
    ${(props) => (props.color ? 'color: ' + props.color + ';' : '')}
  }
  & > a {
    display: inline-block;
    margin-top: 20px;
    height: auto;
    cursor: pointer;
  }
`;

export const Title = styled.div`
  color: ${COLORS.navigationLinkColor};
  font-size: 3.3rem;
  font-weight: bold;
  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

export const Subtitle = styled.div`
  color: ${COLORS.navigationLinkColor};
  margin: 28px auto 0 auto;
  @media (min-width: 769px) {
    max-width: 480px;
    font-size: 1.5rem;
    line-height: 2.4rem;
  }
  @media (max-width: 768px) {
    font-size: 1rem;
    max-width: 100%;
  }
`;
