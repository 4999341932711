import styled, { keyframes } from 'styled-components';
import { COLORS } from '../../../constants/colors';

export const SectionContainer = styled.div`
  & > em {
    display: block;
    text-align: center;
  }
`;

export const Block = styled.div`
  text-align: center;
  & > em:last-child div {
    border: 0;
  }
  padding: 30px 0;
`;

export const IconBoxContainer = styled.div`
  padding: 20px;
  @media (max-width: 768px) {
    padding: 20px 0px;
    margin: 0 20px;
    border-bottom: 1px ${COLORS.boxBorderColor} solid;
  }
  & > svg {
    padding: 15px;
    border-radius: 100%;
    background-color: ${COLORS.primaryColor};
    color: ${COLORS.buttonText};
    margin-bottom: 12px;
  }
`;

export const Title = styled.div`
  color: ${COLORS.sectionTitleColor};
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 8px;
  @media (max-width: 600px) {
    font-size: 1.2rem;
  }
`;

export const Text = styled.p`
  color: ${COLORS.textColor};
  font-size: 1.1rem;
  line-height: 1.5rem;
  margin: 15px 0 0 0;

  @media (max-width: 600px) {
    font-size: 1rem;
  }
`;

export const ImageContainer = styled.div`
  border-radius: 100%;
  background-color: ${COLORS.primaryColor};
  padding: 20px;
  height: 35px;
  width: 35px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`;

export const Image = styled.img`
  filter: ${COLORS.iconColorCssFilter};
  width: 25px;
`;

const positionTop = keyframes`
  from {
    background-position: center 80px;
    background-size: auto 50px;
    opacity: 0;
  }
  to {
    background-position: center -10px;
    background-size: auto 0.5px;
    opacity: 1;
  }
`;

export const Arrow = styled.div`
  animation: ${positionTop};
  animation-timing-function: ease-in-out;
  animation-duration: 2.5s;
  animation-delay: 0ms;
  animation-iteration-count: infinite;

  height: 80px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 50px;

  margin-bottom: 10px;
  transform: rotate(180deg);
  filter: ${COLORS.iconColorCssFilter};

  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE5LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgdmlld0JveD0iMCAwIDMzMCAzMzAiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDMzMCAzMzA7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGlkPSJYTUxJRF8yMjRfIiBkPSJNMzI1LjYwNiwyMjkuMzkzbC0xNTAuMDA0LTE1MEMxNzIuNzksNzYuNTgsMTY4Ljk3NCw3NSwxNjQuOTk2LDc1Yy0zLjk3OSwwLTcuNzk0LDEuNTgxLTEwLjYwNyw0LjM5NA0KCWwtMTQ5Ljk5NiwxNTBjLTUuODU4LDUuODU4LTUuODU4LDE1LjM1NSwwLDIxLjIxM2M1Ljg1Nyw1Ljg1NywxNS4zNTUsNS44NTgsMjEuMjEzLDBsMTM5LjM5LTEzOS4zOTNsMTM5LjM5NywxMzkuMzkzDQoJQzMwNy4zMjIsMjUzLjUzNiwzMTEuMTYxLDI1NSwzMTUsMjU1YzMuODM5LDAsNy42NzgtMS40NjQsMTAuNjA3LTQuMzk0QzMzMS40NjQsMjQ0Ljc0OCwzMzEuNDY0LDIzNS4yNTEsMzI1LjYwNiwyMjkuMzkzeiIvPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPC9zdmc+DQo=');
`;
