import { FC } from 'react';
import { ListTitle, ListContainer, ListItem, ListItems } from './List.style';
import { Button } from '../styled';
import { ButtonType } from '../../types';

export const List: FC<{ title: string; items: []; button: ButtonType }> = ({ title, items, button }) => {
  if (!items) return null;

  return (
    <ListContainer>
      <ListTitle>{title ? title : null}</ListTitle>
      <ListItems>
        {items.map((item, index) => (
          <ListItem key={String(index)}>{item}</ListItem>
        ))}
      </ListItems>
      {!button ? null : (
        <Button layout="primary" to={`${button.url}`}>
          {button.title}
        </Button>
      )}
    </ListContainer>
  );
};
