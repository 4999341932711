import styled from 'styled-components';
import { COLORS } from '../../../constants/colors';

export const Block = styled.div`
  width: 100%;
  height: 100%;
  max-width: 400px;
  margin: auto;
  text-align: center;
  color: ${COLORS.textColor};
`;

export const NavItem = styled.div`
  color: ${COLORS.textColor};
  background: ${COLORS.backgroundColor};
`;
