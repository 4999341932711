import React from 'react';
import sanitizeHtml from 'sanitize-html';
import { Block, Image, SectionContainer, TextContent, Title, Subtitle, Text, ImageContainer } from './ImageText.style';
import { SectionContent } from '../../../types';
import { IMAGE_PATH } from '../../../constants/config';
import { IsVisible } from '../../';

interface SectionParams {
  content: SectionContent[];
  offset?: number;
}

export const ImageText: React.FC<SectionParams> = ({ content, offset = 0 }) => {
  return (
    <SectionContainer>
      {content.map((item, index) => {
        let textPosition = item.textPosition === 'left' ? 'row-reverse' : 'row';

        if (!item.image && !item.title && !item.subtitle && item.text)
          return (
            <IsVisible key={String(index)} index={index} offset={offset}>
              <TextContent position="left">
                <Text
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(item.text, { allowedTags: false, allowedAttributes: false }),
                  }}
                />
              </TextContent>
            </IsVisible>
          );

        return (
          <IsVisible key={String(index)} index={index} offset={offset}>
            <Block textPosition={textPosition}>
              {item.image ? (
                <ImageContainer position={item.textPosition || 'left'}>
                  <Image
                    size={item.text ? '50%' : '100%'}
                    src={item.image.indexOf('://') > 0 ? item.image : IMAGE_PATH + item.image}
                    css={item.css}
                  />
                </ImageContainer>
              ) : null}

              {item.text ? (
                <TextContent position={item.textPosition || 'left'}>
                  {!item.subtitle ? null : <Subtitle>{item.subtitle}</Subtitle>}
                  {!item.title ? null : <Title>{item.title}</Title>}
                  <Text
                    dangerouslySetInnerHTML={{
                      __html: sanitizeHtml(item.text, { allowedTags: false, allowedAttributes: false }),
                    }}
                  />
                </TextContent>
              ) : null}
            </Block>
          </IsVisible>
        );
      })}
    </SectionContainer>
  );
};
