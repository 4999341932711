import React, { useState } from 'react';
import {
  NavigationContainer,
  NavItems,
  NavItem,
  NavLink,
  NavBar,
  Hamburger,
  StyledLogo,
  Logo,
} from './CMSNavigation.style';
import { FormButton } from '../../styled';
import { Link } from 'react-router-dom';
import { api } from '../Api';
import { useAdminContext } from '../../../context/AdminContext';
import { NavigationProps } from '../../../types';

export const CMSNavigation: React.FC<{ selectedLink: string }> = ({ selectedLink = '' }) => {
  const [store, setStore] = useAdminContext();
  const [mobileNavOpened, setMobileNavOpened] = useState<boolean>(false);

  const NavigationData: NavigationProps = {
    links: [
      {
        title: 'Pages',
        url: '/c/m/s/pages',
      },
    ],
    buttons: [
      {
        title: 'Sign out',
        style: 'primary',
        url: '',
        onclick: () => {
          api({ do: 'cms', action: 'logout', email: store.email, token: store.token }, (data) => {
            if (data.status === 'logout') {
              setStore({ token: '' });
            }
          });
        },
      },
    ],
  };

  return (
    <NavigationContainer mobile={mobileNavOpened}>
      <Logo>
        <Link to="/">
          <StyledLogo />
        </Link>
      </Logo>
      <NavBar mobile={mobileNavOpened}>
        <NavItems>
          {!NavigationData || !NavigationData.links
            ? null
            : NavigationData.links.map((item, index) => (
                <NavItem key={String(index)}>
                  <NavLink
                    onClick={() => setMobileNavOpened(false)}
                    to={`${item.url}`}
                    selectedlink={String(selectedLink === item.url)}
                  >
                    {item.title}
                  </NavLink>
                </NavItem>
              ))}
        </NavItems>
        {!NavigationData || !NavigationData.buttons
          ? null
          : NavigationData.buttons.map((item, index) => (
              <FormButton key={String(index)} layout={item.style ? item.style : 'default'} onClick={item.onclick}>
                {item.title}
              </FormButton>
            ))}
      </NavBar>

      <Hamburger active={mobileNavOpened} onClick={() => setMobileNavOpened(!mobileNavOpened)}>
        <div></div>
        <div></div>
      </Hamburger>
    </NavigationContainer>
  );
};
