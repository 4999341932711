import { useEffect, useState, FC, memo } from 'react';
import { PageContent, PageWrapper } from './Page.style';
import { GlobalStyle, GlobalStyleReset } from '../../lib/styles';
import { Navigation, Footer } from '../../components';
import { SectionType, MatchParams } from '../../types';
import { SectionContainer } from '../../components';
import { api } from '../../components/CMSAdmin/Api';
import { SITE_NAME } from '../../constants/config';
import { Redirect } from 'react-router-dom';

export const Page: FC<MatchParams> = ({ match }) => {
  const [pageExists, setPageExists] = useState<boolean>(true);
  const [sections, setSections] = useState<SectionType[]>([]);

  useEffect(() => {
    let url = match.url.substring(1) || 'homepage';

    api({ do: 'cms', action: 'getData', page: 'pages/' + url }, (data: any) => {
    if (data.status === 'done' && data.data && Object.keys(data.data).length) {
      document.title = data.data.browserTitle || SITE_NAME;
        setPageExists(true);
        setSections(data.data.sections);
        window.scrollTo(0, 0);
    } else {
        setPageExists(false);
        setSections([]);
    }
    });
  }, [match.url]);

  return (
    <PageWrapper>
      <PageContent>
        <GlobalStyleReset />
        <GlobalStyle />
        <Navigation activeLink={match.url} />
        { pageExists ? <PageIn sections={sections} /> : <Redirect to="/" /> }
      </PageContent>
      <Footer />
    </PageWrapper>
  );
};


type PageInProps = {
  sections: SectionType[]
}
const PageIn: FC<PageInProps> = memo(({ sections }) => <SectionContainer sections={sections} />);
