import styled from "styled-components";

export const Block = styled.div<{ textPosition: string }>`
  padding: 20px;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  @media (max-width: 768px) {
    & {
      padding: 0;
    }
  }
`;
