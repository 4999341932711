import { FC } from 'react';
import {
  ImageText,
  InfoBox,
  IconBox,
  Form,
  Price,
  Faq,
  Review,
  Timeline,
  Team,
  ButtonLinks,
  BackgroundImage,
  Career,
  StepList,
  Gallery,
} from '..';
import { SectionType, SectionForm, SectionContent } from '../../../types';

export const PageSectionRouter: FC<{ type: string; data: SectionType }> = ({ type, data }) => {
  let DisplayComponent = <></>;

  if (!data.content) return null;

  switch (type) {
    case 'Form': {
      DisplayComponent = (
        <Form
          formData={
            {
              submitUrl: data.formSubmitUrl,
              buttonLabel: data.formButtonLabel,
            } as SectionForm
          }
          content={data.content as SectionContent[]}
        />
      );
      break;
    }
    case 'Buttons': {
      DisplayComponent = <ButtonLinks buttons={data.buttons} />;
      break;
    }
    case 'InfoBox': {
      DisplayComponent = <InfoBox offset={data.effectOffset} content={data.content} />;
      break;
    }
    case 'Faq': {
      DisplayComponent = <Faq offset={data.effectOffset} content={data.content} />;
      break;
    }
    case 'IconBox': {
      DisplayComponent = <IconBox offset={data.effectOffset} content={data.content} />;
      break;
    }
    case 'ImageText': {
      DisplayComponent = <ImageText offset={data.effectOffset} content={data.content} />;
      break;
    }
    case 'Timeline': {
      DisplayComponent = <Timeline offset={data.effectOffset} content={data.content} />;
      break;
    }
    case 'Price': {
      DisplayComponent = <Price offset={data.effectOffset} content={data.content} />;
      break;
    }
    case 'StepList': {
      DisplayComponent = <StepList offset={data.effectOffset} content={data.content} />;
      break;
    }
    case 'Review': {
      DisplayComponent = <Review offset={data.effectOffset} content={data.content} />;
      break;
    }
    case 'Team': {
      DisplayComponent = <Team offset={data.effectOffset} content={data.content} />;
      break;
    }
    case 'Career': {
      DisplayComponent = <Career offset={data.effectOffset} content={data.content} />;
      break;
    }
    case 'BackgroundImage': {
      DisplayComponent = <BackgroundImage offset={data.effectOffset} content={data.content} />;
      break;
    }
    case 'Gallery': {
      DisplayComponent = <Gallery offset={data.effectOffset} content={data.content} />;
      break;
    }
    default: {
      DisplayComponent = <></>;
    }
  }
  return DisplayComponent;
};
