import styled, { keyframes } from 'styled-components';
import { COLORS } from '../../../constants/colors';
import { Link } from 'react-router-dom';
import { ReactComponent as DOSLogo } from '../../../assets/devocean-solutions-logo-anim-bg.svg';

interface NavBarProps {
  mobile: boolean;
}

const rotate = keyframes`
  from {
    transform: rotate(-360deg);
  }
  to {
    transform: rotate(0deg);
  }
`;

export const StyledLogo = styled(DOSLogo)`
  height: 70px;
  margin-top: 5px;

  & #logo-background {
    fill: ${COLORS.backgroundColor};
  }
  & #cog-animation-mask {
    fill: ${COLORS.backgroundColor};
  }
  & #cog1 {
    animation: 20s ${rotate} infinite linear;
    transform-origin: 50% 50%;
    transform-box: fill-box;
  }
`;

export const Logo = styled.div`
  width: 150px;
`;

export const NavigationContainer = styled.div<NavBarProps>`
  & {
    height: 80px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    z-index: 100;
    position: sticky;
    top: 0;
    background-color: ${COLORS.backgroundColor};
    overflow: ${(props) => (props.mobile ? 'visible' : 'hidden')};
  }
`;

export const NavItems = styled.ul`
  list-style-type: none;
  padding: 0;
  flex: 1;
  text-align: center;
  padding-top: 10px;
`;
export const NavItem = styled.li`
  display: inline;
  margin-left: 10px;
  margin-right: 10px;
`;
export const NavLink = styled(Link)<{ selectedlink: string }>`
  text-decoration: none;
  color: ${COLORS.textColor};
  padding: 5px;
  font-weight: ${(props) => (props.selectedlink === 'true' ? 'bold' : 'normal')};
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
  &:hover {
    border-bottom: 2px ${COLORS.secondColor} solid;
  }
`;

export const NavBar = styled.div<NavBarProps>`
  flex: 1;
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    & {
      display: block;
      position: absolute;
      background-color: ${(props) => (props.mobile ? COLORS.backgroundColor : 'transparent')};
      top: 80px;
      width: 100%;
      text-align: right;
      padding-bottom: 20px;
      border-bottom: 1px solid ${COLORS.primaryColor};
      transition: 0.5s;
      transform: translateX(${(props) => (props.mobile ? '0' : '100%')});
    }

    & > ul li {
      display: block;
      text-align: right;
      padding: 10px;
      font-size: 1.5rem;
      margin-right: 0;
    }
  }
`;

export const Hamburger = styled.a<{ active: boolean }>`
  & {
    display: inline-block;
    width: 28px;
    border-top: 2px solid ${(props) => (props.active ? 'transparent' : COLORS.textColor)};
    margin: 20px;
    z-index: 1;
    cursor: pointer;
    transition: 0.3s;
  }
  & > div:first-of-type {
    transform: rotateZ(${(props) => (props.active ? '45deg' : '0')});
    transition: 0.3s;
    transform-origin: center;
  }
  & > div:last-of-type {
    transform: rotateZ(${(props) => (props.active ? '135deg' : '0')});
    margin-top: ${(props) => (props.active ? '-2px' : '6px')};
    transition: 0.3s;
    transform-origin: center;
  }
  & > div {
    & {
      border-bottom: ${(props) => (props.active ? '2px' : '2px')} solid ${COLORS.textColor};
      margin-top: 6px;
      width: 100%;
      float: right;
    }
  }
  &:link {
    animation: ${rotate} 0.2s linear;
  }
  @media (min-width: 768px) {
    display: none;
  }
`;
